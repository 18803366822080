import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

const SelectCoin = (props) => {
    useEffect(() => {
        if (props.partnerTokens.length !== 0 && props.token.symbol === '') {
            props.setToken((prevState) => ({...prevState, ...props.partnerTokens[0]}))
        }
    }, [props.partnerTokens, props.token])

    if (props.partnerTokens.length === 0) {
        return null
    }

    return <div className="select-coin">
        <div className="select-coin__value">
            <span className="field-unit">
                {props.token.symbol}
            </span>
            <svg className="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0.529297L5 4.76459L9 0.529297" stroke="white"></path>
            </svg>
        </div>
        <ul className="select-coin__list">
            {props.partnerTokens.map((item) => {
                return <li
                    key={item.symbol}
                    onClick={() => {
                        props.setToken((prevState) => ({...prevState, ...item}))
                    }}
                >
                    <span className="field-unit">
                        {item.symbol}
                    </span>
                </li>
            })}
        </ul>
    </div>
}

SelectCoin.propTypes = {
    partnerTokens: PropTypes.array.isRequired,
    token: PropTypes.shape({
        value: PropTypes.string.isRequired,
        decimals: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
    }).isRequired,
    setToken: PropTypes.func.isRequired,
}
export default SelectCoin
