import React from 'react'
import {PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {sendingTransaction, sleep} from '../../utils/functions'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import {requestProfileAtomicAssets, requestWNFTs} from '../../store/profileSlice'

const TransferBackButton = (props) => {
    const dispatch = useDispatch()

    return <li>
        <button
            onClick={() => {
                const tr = {
                    actions: [
                        {
                            account: PROTOCOL_CONTRACT,
                            name: 'transferback',
                            authorization: [{
                                actor: props.ual.activeUser.accountName,
                                permission: 'active',
                            }],
                            data: {
                                wrapped_token_id: props.token.wrapped_token_id,
                            }
                        }
                    ]
                }
                const callback = async () => {
                    await sleep(SLEEP_AFTER_TRANSACTION)
                    dispatch(requestProfileAtomicAssets(props.ual.activeUser.accountName))
                    dispatch(requestWNFTs(props.ual.activeUser.accountName))
                }
                sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
            }}
        >
            Transfer back
        </button>
    </li>
}

TransferBackButton.propTypes = {
    token: PropTypes.object.isRequired,
    ual: PropTypes.object,
}
export default withUAL(TransferBackButton)
