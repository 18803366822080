import * as waxjs from '@waxio/waxjs/dist'
import {
    MARKETPLACE_CONTRACT,
    PROTOCOL_CONTRACT, TOKENS_PER_PAGE,
    WAX_RPC_ENDPOINT
} from './constant.js'
import {nameToInt} from './functions'

const wax = new waxjs.WaxJS({
    rpcEndpoint: WAX_RPC_ENDPOINT
})

export const WaxApi = {
    loadApprovedAccounts: async (user) => {
        let nextKey = 0
        let more = true
        let approvals = []
        while (more) {
            let result = await wax.rpc.get_table_rows({
                code: PROTOCOL_CONTRACT,
                table: 'approvals',
                scope: user,
                lower_bound: nextKey,
            })
            approvals.push(...result.rows)
            nextKey = result.next_key
            more = result.more
        }
        return approvals
    },
    loadBalance: async (user) => {
        const balance = await wax.rpc.get_table_rows({
            code: 'eosio.token',
            table: 'accounts',
            scope: user,
        })
        return balance.rows[0] ? balance.rows[0].balance : null
    },
    loadExternalBalances: async (user, tokens) => {
        let balances = []
        for (let i = 0; i < tokens.length; i++) {
            const result = await wax.rpc.get_table_rows({
                code: tokens[i].partner_token_contract,
                table: 'accounts',
                scope: user,
            })
            for (let j = 0; j < result.rows.length; j++) {
                if (result.rows[j].balance.split(' ')[1] === tokens[i].symbol) {
                    balances.push(result.rows[j])
                    break
                }
            }
        }
        return balances
    },
    loadMarketOffers: async (nextKey = 0) => {
        return await wax.rpc.get_table_rows({
            code: MARKETPLACE_CONTRACT,
            table: 'offers',
            scope: MARKETPLACE_CONTRACT,
            lower_bound: nextKey,
            limit: TOKENS_PER_PAGE,
        })
    },
    loadPartnerTokens: async () => {
        const tokens = await wax.rpc.get_table_rows({
            code: PROTOCOL_CONTRACT,
            table: 'ptokens',
            scope: PROTOCOL_CONTRACT,
        })
        return tokens.rows
    },
    loadToken: async (tokenId) => {
        const result = await wax.rpc.get_table_rows({
            code: PROTOCOL_CONTRACT,
            table: 'tokens',
            scope: PROTOCOL_CONTRACT,
            key_type: 'i64',
            lower_bound: tokenId,
            upper_bound: tokenId
        })
        return result.rows[0]
    },
    loadTokenCollateral: async (tokenId) => {
        return await wax.rpc.get_table_rows({
            code: PROTOCOL_CONTRACT,
            table: 'collaterals',
            scope: tokenId,
        })
    },
    loadTokenOffer: async (tokenId) => {
        return await wax.rpc.get_table_rows({
            code: MARKETPLACE_CONTRACT,
            table: 'offers',
            scope: MARKETPLACE_CONTRACT,
            index_position: 2,
            key_type: 'i64',
            lower_bound: tokenId,
            upper_bound: tokenId,
        })
    },
    loadUserBalances: async (user) => {
        let nextKey = 0
        let more = true
        let balances = []
        while (more) {
            let result = await wax.rpc.get_table_rows({
                code: PROTOCOL_CONTRACT,
                table: 'balances',
                scope: user,
                lower_bound: nextKey,
            })
            balances.push(...result.rows)
            nextKey = result.next_key
            more = result.more
        }
        return balances
    },
    loadUserWNFTs: async (user) => {
        let nextKey = (BigInt(nameToInt(user)) << 64n).toString()
        const upperBound = (((BigInt(nameToInt(user)) + 1n) << 64n) - 1n).toString()
        let more = true
        let wnfts = []
        while (more) {
            let result = await wax.rpc.get_table_rows({
                code: PROTOCOL_CONTRACT,
                table: 'tokens',
                scope: PROTOCOL_CONTRACT,
                index_position: 2,
                key_type: 'i128',
                lower_bound: nextKey,
                upper_bound: upperBound,
            })
            wnfts.push(...result.rows)
            nextKey = result.next_key
            more = result.more
        }
        return wnfts
    },
}
