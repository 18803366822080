import React, {useEffect, useMemo} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import {useParams} from 'react-router-dom'
import ProfileAccountInfo from './ProfileAccountInfo'
import TokensList from '../tokens/TokensList'
import {
    getBalances,
    getProfileAtomicAssets, getWaitingTokens, getWrappedTokens, requestBalances,
    requestProfileAtomicAssets,
    requestWNFTs, resetProfile,
} from '../../store/profileSlice'
import AddCollateralModal from '../modals/AddCollateralModal'
import {
    getAddCollateralModal,
    getApproveAccountModal,
    getCreateOfferModal,
    getDepositModal,
    getTransferModal, getWithdrawModal
} from '../../store/appSlice'
import DepositModal from '../modals/DepositModal'
import TransferModal from '../modals/TransferModal'
import CreateOfferModal from '../modals/CreateOfferModal'
import ApproveAccountModal from '../modals/ApproveAccountModal'
import WithdrawModal from '../modals/WithdrawModal'
import PropTypes from "prop-types";

const Profile = (props) => {
    const addCollateralModal = useSelector(getAddCollateralModal)
    const approveAccountModal = useSelector(getApproveAccountModal)
    const atomicAssets = useSelector(getProfileAtomicAssets)
    const createOfferModal = useSelector(getCreateOfferModal)
    const depositModal = useSelector(getDepositModal)
    const withdrawModal = useSelector(getWithdrawModal)
    const waitingTokens = useSelector(getWaitingTokens, shallowEqual)
    const wrappedTokens = useSelector(getWrappedTokens, shallowEqual)
    const transferModal = useSelector(getTransferModal)
    const balances = useSelector(getBalances)

    const {user} = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestWNFTs(user))
        dispatch(requestProfileAtomicAssets(user))
        dispatch(requestBalances(user))
        return () => {
            dispatch(resetProfile())
        }
    }, [user])

    const accountInfo = useMemo(() => <ProfileAccountInfo
        accountName={user}
        balances={balances}
        isOwner={props.ual.activeUser ? user === props.ual.activeUser.accountName : false}
    />, [user, balances])
    const atomicAssetsList = useMemo(() => {
        return <TokensList
            title={'Discovered NFTs'}
            tokens={atomicAssets}
            type={'atomicAssets'}
        />
    }, [atomicAssets])
    const waitingList = useMemo(() => {
        return <TokensList
            title={'Waiting...'}
            tokens={waitingTokens}
            type={'waiting'}
        />
    }, [waitingTokens])
    const wrappedList = useMemo(() => {
        return <TokensList
            title={'wNFTs'}
            tokens={wrappedTokens}
            type={'wrapped'}
        />
    }, [wrappedTokens])

    return <>
        <main className="s-main">
            {accountInfo}
            {waitingTokens && waitingTokens.length > 0 && waitingList}
            {wrappedList}
            {atomicAssetsList}
        </main>
        {addCollateralModal && <AddCollateralModal
            profile={true}
            ual={props.ual}
        />}
        {transferModal && <TransferModal
            profile={true}
            ual={props.ual}
        />}
        {depositModal && <DepositModal
            profile={true}
            ual={props.ual}
        />}
        {withdrawModal && <WithdrawModal
            profile={true}
            ual={props.ual}
        />}
        {createOfferModal && <CreateOfferModal
            profile={true}
            ual={props.ual}
        />}
        {approveAccountModal && <ApproveAccountModal
            ual={props.ual}
        />}
    </>
}

Profile.propTypes = {
    ual: PropTypes.object,
}
export default withUAL(Profile)
