import React from 'react'
import PropTypes from 'prop-types'
import {unixtimeToStr} from '../../utils/functions'

const TokenUnwrapAfterBlock = (props) => {
    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">Unwrap after</label>
        </div>
        <div className="field-row">
            <div className="field-control">
                <span>{props.unwrapAfter > 0 ? unixtimeToStr(props.unwrapAfter * 1000) : '—'}</span>
            </div>
        </div>
    </div>
}

TokenUnwrapAfterBlock.propTypes = {
    unwrapAfter: PropTypes.number.isRequired,
}
export default TokenUnwrapAfterBlock
