import React from 'react'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import CopyBlock from '../CopyBlock'
import {ATOMICASSETS_CONTRACT, ATOMICASSETS_LAYOUT_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {sendingTransaction, sleep} from '../../utils/functions'
import {requestProfileAtomicAssets, requestWNFTs} from '../../store/profileSlice'

import icon_logo from '../../static/pics/logo.svg'

const AtomicAssetsShort = (props) => {
    const dispatch = useDispatch()

    const isOwner = () => {
        return props.ual.activeUser && props.asset.owner === props.ual.activeUser.accountName
    }
    const transferForWrap = () => {
        const tr = {
            actions: [
                {
                    account: ATOMICASSETS_CONTRACT,
                    name: 'transfer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        from: props.ual.activeUser.accountName,
                        to: ATOMICASSETS_LAYOUT_CONTRACT,
                        asset_ids: [props.asset.asset_id],
                        memo: 'wrap'
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestProfileAtomicAssets(props.ual.activeUser.accountName))
            dispatch(requestWNFTs(props.ual.activeUser.accountName))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <div className="c-col">
        <div className="w-card w-card-preview">
            <div className="bg">
                <div className="w-card__nft">
                    <div>
                        <CopyBlock
                            text={props.asset.collection.collection_name}
                            title={'Collection'}
                            width={512}
                        />
                        <CopyBlock
                            text={props.asset.asset_id}
                            title={'ID'}
                            width={512}
                        />
                    </div>
                </div>
                <div className="w-card__token">
                    {!props.asset.data.img || props.asset.data.img === '' ?
                        <div className="inner">
                            <div className="default">
                                <img src={icon_logo} alt=""/>
                                <span>Cannot load NON-FUNGIBLE TOKEN Preview</span>
                            </div>
                        </div>
                        :
                        <div className="inner">
                            <video
                                className="img"
                                src={`https://ipfs.io/ipfs/${props.asset.data.img}`}
                                poster={`https://ipfs.io/ipfs/${props.asset.data.img}`}
                                autoPlay={true}
                                muted={true}
                                loop={true}
                            />
                        </div>
                    }
                </div>
                <div className="w-card__param">
                    <div className="form-row">
                        <div className="field-wrap">
                            <div className="field-row">
                                <label className="field-label">Token name</label>
                            </div>
                            <div className="field-row">
                                <div className="field-control">
                                    <span>{props.asset.data.name || '-'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOwner() && <button
                        className="btn btn-lg btn-primary"
                        onClick={transferForWrap}
                    >Transfer for wrap
                    </button>
                    }
                </div>
            </div>
        </div>
    </div>
}

AtomicAssetsShort.propTypes = {
    asset: PropTypes.object.isRequired,
    ual: PropTypes.object,
}
export default withUAL(AtomicAssetsShort)
