import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import Header from './components/Header'
import {requestPartnerTokens} from './store/appSlice'
import MainPage from './components/MainPage'
import NotFound from './components/NotFound'
import Profile from './components/profile/Profile'
import Modals from './components/modals/Modals'
import TokenDetails from './components/tokens/TokenDetails'
import Footer from './components/Footer'
import Market from './components/market/Market'

const App = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestPartnerTokens())
    }, [])

    return <BrowserRouter>
        <Header/>
        <Routes>
            <Route exect path="/" element={<MainPage/>}/>
            <Route exact path="/market" element={<Market/>}/>
            <Route exact path="/profile/:user" element={<Profile/>}/>
            <Route path="/tokens/:tokenId" element={<TokenDetails/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
        <Modals/>
        <Footer/>
    </BrowserRouter>
}

export default App