import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {COPIED_HINT_TIMEOUT} from '../utils/constant'

import icon_i_copy from '../static/pics/i-copy.svg'

const CopyBlock = (props) => {
    const [copiedHint, setCopiedHint] = useState(false)

    return <CopyToClipboard
        text={props.text}
        onCopy={() => {
            setCopiedHint(true)
            setTimeout(() => {
                setCopiedHint(false)
            }, COPIED_HINT_TIMEOUT)
        }}
    >
        <button className={props.className || 'btn-copy'}>
            {props.isTippy ?
                <Tippy
                    content={props.text}
                    appendTo={document.getElementsByClassName("wrapper")[0]}
                    trigger='mouseenter'
                    interactive={false}
                    arrow={false}
                    maxWidth={props.width || 512}
                >
                    <span>
                        {props.title && <span className="title">{props.title}</span>}
                        {!props.hideText && props.text}
                    </span>
                </Tippy>
                :
                <span>
                    {props.title && <span className="title">{props.title}</span>}
                    {!props.hideText && props.text}
                </span>
            }
            <img src={props.icon || icon_i_copy} alt=""/>
            {copiedHint && <span className="btn-action-info">Copied</span>}
        </button>
    </CopyToClipboard>
}

CopyBlock.propTypes = {
    className: PropTypes.string,
    hideText: PropTypes.bool,
    icon: PropTypes.string,
    isTippy: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    title: PropTypes.string,
    width: PropTypes.number,
}
export default CopyBlock
