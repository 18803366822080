import React from 'react'
import PropTypes from 'prop-types'
import {addThousandSeparator} from '../../utils/functions'
import BigNumber from 'bignumber.js'

import default_icon from '../../static/pics/coins/_default.png'

const TokenTransferFeeBlock = (props) => {
    const transferFee = new BigNumber(props.transferFee.split(' ')[0])
    const feeSymbol = transferFee.gt(0) ? props.transferFee.split(' ')[1] : null

    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">Transfer Fee</label>
        </div>
        <div className="field-row">
            <div className="field-control">
                <span>
                    {transferFee.gt(0) ? addThousandSeparator(transferFee.toString()) : '—'}
                </span>
            </div>
            {feeSymbol ?
                <span className="field-unit">
                    <span className="i-coin"><img src={default_icon} alt=""/></span>
                    {feeSymbol}
                </span>
                :
                null
            }
        </div>
    </div>
}


TokenTransferFeeBlock.propTypes = {
    transferFee: PropTypes.string.isRequired,
}
export default TokenTransferFeeBlock
