import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AtomicAssetsApi} from '../utils/atomicAssetsApi'
import {WaxApi} from '../utils/waxApi'

export const requestBalances = createAsyncThunk(
    'profile/requestBalances',
    async (account) => {
        return await WaxApi.loadUserBalances(account)
    }
)
export const requestMetadata = createAsyncThunk(
    'profile/requestMetadata',
    async (id) => {
        return {id, data: await AtomicAssetsApi.getAssetMetadata(id)}
    }
)
export const requestOfferByTokenId = createAsyncThunk(
    'profile/requestOfferByTokenId',
    async (id) => {
        return {id, data: (await WaxApi.loadTokenOffer(id)).rows[0]}
    }
)
export const requestProfileAtomicAssets = createAsyncThunk(
    'profile/requestProfileAtomicAssets',
    async (account) => {
        return await AtomicAssetsApi.getAssets(account)
    }
)
export const requestTokenCollateral = createAsyncThunk(
    'profile/requestTokenCollateral',
    async (id) => {
        return {id, collateral: (await WaxApi.loadTokenCollateral(id)).rows}
    }
)
export const requestWNFTs = createAsyncThunk(
    'profile/requestWNFTs',
    async (account) => {
        return await WaxApi.loadUserWNFTs(account)
    }
)

const initialState = {
    atomicAssets: null,
    balances: [],
    collateral: {},
    metadata: {},
    offers: {},
    wNFTs: null,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        resetProfile: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(requestBalances.fulfilled, (state, action) => {
            state.balances = action.payload
        })
        builder.addCase(requestMetadata.fulfilled, (state, action) => {
            state.metadata = {...state.metadata, [action.payload.id]: action.payload.data}
        })
        builder.addCase(requestOfferByTokenId.fulfilled, (state, action) => {
            state.offers = {...state.offers, [action.payload.id]: action.payload.data}
        })
        builder.addCase(requestProfileAtomicAssets.fulfilled, (state, action) => {
            state.atomicAssets = action.payload
        })
        builder.addCase(requestTokenCollateral.fulfilled, (state, action) => {
            state.collateral = {...state.collateral, [action.payload.id]: action.payload.collateral}
        })
        builder.addCase(requestWNFTs.fulfilled, (state, action) => {
            state.wNFTs = action.payload
        })
    },
})

export const getBalances = (state) => state.profile.balances
export const getTokenCollaterals = (state) => state.profile.collateral
export const getTokenCollateralById = (id) => (state) => {
    const collateral = state.profile.collateral[id]
    if (!collateral) {
        return []
    }
    let result = []
    for (let i = 0; i < collateral.length; i++) {
        result[i] = {
            ...collateral,
            amount: collateral[i].quantity.split(' ')[0],
            symbol: collateral[i].quantity.split(' ')[1],
        }
    }
    return result
}
export const getTokenMetadata = (id) => (state) => state.profile.metadata[id] || null
export const getOfferByTokenId = (id) => (state) => state.profile.offers[id] || null
export const getProfileAtomicAssets = (state) => state.profile.atomicAssets
export const getWaitingTokens = (state) => {
    return state.profile.wNFTs ? state.profile.wNFTs.filter((item) => !item.wrapped) : null
}
export const getWrappedTokens = (state) => {
    return state.profile.wNFTs ? state.profile.wNFTs.filter((item) => item.wrapped) : null
}

export const {resetProfile} = profileSlice.actions

export default profileSlice.reducer
