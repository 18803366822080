import React, {useDispatch, useSelector} from 'react-redux'
import {
    getModalError,
    getModalText,
    getModalType,
    setModalError,
    setModalText,
    setModalType
} from '../../store/appSlice'

import icon_envelop from '../../static/pics/envelop.svg'
import icon_error from '../../static/pics/i-error.svg'

const Modals = () => {
    const modalError = useSelector(getModalError)
    const modalText = useSelector(getModalText)
    const modalType = useSelector(getModalType)

    const dispatch = useDispatch()

    if (!modalType) {
        return null
    }

    const Buttons = () => <div className="col-12 col-sm-auto mb-3 mb-md-0">
        <button
            className="btn btn-grad"
            onClick={() => {
                dispatch(setModalError(null))
                dispatch(setModalText(null))
                dispatch(setModalType(null))
            }}
        >ACCEPT THIS FACT
        </button>
    </div>

    let getModalContent = () => {
        switch (modalType) {
            case 'error':
                return <div className="c-success">
                    <img className="c-success__img" src={icon_error} alt=""/>
                    <div className="h2">{modalError.name || 'Error Screen'}</div>
                    {modalError.message ? <p>{modalError.message}</p> : null}
                    <div className="modal__btns">
                        <Buttons/>
                    </div>
                </div>
            case 'loading':
                return <div className="c-success">
                    <img className="c-success__img" src={icon_envelop} alt=""/>
                    <div className="h2">
                        Loading
                        <span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>
                    </div>
                    <p>{modalText}</p>
                </div>
            case 'info':
                return <div className="c-success">
                    <img className="c-success__img" src={icon_envelop} alt=""/>
                    <div className="h2"/>
                    <p>this.state.info.text</p>
                    <div className="modal__btns">
                        <Buttons/>
                    </div>
                </div>
            default:
                return null
        }
    }

    return <div className="modal">
        <div className="modal__inner">
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    {getModalContent()}
                </div>
            </div>
        </div>
    </div>
}

export default Modals
