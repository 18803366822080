import {configureStore} from '@reduxjs/toolkit'
import accountReducer from './accountSlice'
import appReducer from './appSlice'
import offerReducer from './offerSlice'
import profileReducer from './profileSlice'
import tokenReducer from './tokenSlice'

const store = configureStore({
    reducer: {
        account: accountReducer,
        app: appReducer,
        offer: offerReducer,
        profile: profileReducer,
        token: tokenReducer,
    },
})

export default store
