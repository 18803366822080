import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {WaxApi} from '../utils/waxApi'

export const requestPartnerTokens = createAsyncThunk(
    'app/requestPartnerTokens',
    async () => {
        return await WaxApi.loadPartnerTokens()
    }
)

const initialState = {
    addCollateralModal: false,
    addCollateralToken: null,
    approveAccountModal: false,
    createOfferModal: false,
    createOfferToken: null,
    depositModal: false,
    modalError: null,
    modalText: null,
    modalType: null,
    partnerTokens: [],
    transferModal: false,
    transferToken: null,
    withdrawModal: false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAddCollateralModal: (state, action) => {
            state.addCollateralModal = action.payload
        },
        setAddCollateralToken: (state, action) => {
            state.addCollateralToken = action.payload
        },
        setApproveAccountModal: (state, action) => {
            state.approveAccountModal = action.payload
        },
        setCreateOfferModal: (state, action) => {
            state.createOfferModal = action.payload
        },
        setCreateOfferToken: (state, action) => {
            state.createOfferToken = action.payload
        },
        setDepositModal: (state, action) => {
            state.depositModal = action.payload
        },
        setModalError: (state, action) => {
            state.modalError = action.payload
        },
        setModalText: (state, action) => {
            state.modalText = action.payload
        },
        setModalType: (state, action) => {
            state.modalType = action.payload
        },
        setTransferModal: (state, action) => {
            state.transferModal = action.payload
        },
        setTransferToken: (state, action) => {
            state.transferToken = action.payload
        },
        setWithdrawModal: (state, action) => {
            state.withdrawModal = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestPartnerTokens.fulfilled, (state, action) => {
            state.partnerTokens = action.payload
        })
    },
})

export const getAddCollateralModal = (state) => state.app.addCollateralModal
export const getAddCollateralToken = (state) => state.app.addCollateralToken
export const getApproveAccountModal = (state) => state.app.approveAccountModal
export const getCreateOfferModal = (state) => state.app.createOfferModal
export const getCreateOfferToken = (state) => state.app.createOfferToken
export const getDepositModal = (state) => state.app.depositModal
export const getModalError = (state) => state.app.modalError
export const getModalText = (state) => state.app.modalText
export const getModalType = (state) => state.app.modalType
export const getPartnerTokens = (state) => {
    return state.app.partnerTokens.map((item) => {
        let asset = item.max_supply.split(' ')
        let decimals = asset[0].split('.')
        return {
            ...item,
            symbol: asset[1],
            decimals: decimals.length === 2 ? decimals[1].length : 0
        }
    })
}
export const getTransferModal = (state) => state.app.transferModal
export const getTransferToken = (state) => state.app.transferToken
export const getWithdrawModal = (state) => state.app.withdrawModal

export const {
    setAddCollateralModal,
    setAddCollateralToken,
    setApproveAccountModal,
    setCreateOfferModal,
    setCreateOfferToken,
    setDepositModal,
    setModalError,
    setModalText,
    setModalType,
    setTransferModal,
    setTransferToken,
    setWithdrawModal,
} = appSlice.actions

export default appSlice.reducer
