import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import BigNumber from 'bignumber.js'
import {addThousandSeparator, sendingTransaction, sleep, unixtimeToStr} from '../../utils/functions'
import {
    MARKETPLACE_CONTRACT,
    PROTOCOL_CONTRACT,
    SLEEP_AFTER_TRANSACTION
} from '../../utils/constant'
import {requestProfileAtomicAssets, requestWNFTs} from '../../store/profileSlice'
import {requestOffers, resetMarket} from '../../store/offerSlice'

const TokenCardFooter = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const buyToken = () => {
        const tr = {
            actions: [
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        from: props.ual.activeUser.accountName,
                        to: MARKETPLACE_CONTRACT,
                        quantity: props.token.price,
                        memo: `fill ${props.token.offer_id}`,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(resetMarket())
            dispatch(requestOffers())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const cancelOffer = () => {
        const tr = {
            actions: [
                {
                    account: MARKETPLACE_CONTRACT,
                    name: 'canceloffer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        offer_id: props.token.offer_id,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(resetMarket())
            dispatch(requestOffers())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const unwrap = () => {
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'unwrap',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        wrapped_token_id: props.token.wrapped_token_id,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestWNFTs(props.ual.activeUser.accountName))
            dispatch(requestProfileAtomicAssets(props.ual.activeUser.accountName))
            navigate(`/profile/${props.ual.activeUser.accountName}`)
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    const conditions = []
    if (props.token.unwrap_after > (Date.now() / 1000)) {
        conditions.push(unixtimeToStr(props.token.unwrap_after * 1000))
    }
    let unwraptFeeThreshold = new BigNumber(props.token.unwrapt_fee_threshold)
    let accumulatedFee = new BigNumber(props.token.accumulated_fee.split(' ')[0])
    const decimals = props.token.accumulated_fee.split(' ')[0].split('.')
    if (decimals.length === 2) {
        unwraptFeeThreshold /= 10 ** decimals[1].length
    }
    if (unwraptFeeThreshold && accumulatedFee.lt(unwraptFeeThreshold)) {
        conditions.push(`${addThousandSeparator(unwraptFeeThreshold.toString())} ${props.token.accumulated_fee.split(' ')[1]}`)
    }

    switch (props.type) {
        case 'offers':
            if (props.isOwner) {
                return <button
                    className="btn btn-lg btn-yellow"
                    onClick={cancelOffer}
                >Cancel offer
                </button>
            } else {
                return <button
                    className="btn btn-lg btn-primary"
                    disabled={!props.ual.activeUser}
                    onClick={buyToken}
                >Buy token for {props.token.price}
                </button>
            }
        case 'waiting':
            if (props.isOwner) {
                return <Link
                    className="btn btn-lg btn-primary"
                    to={`/tokens/${props.token.wrapped_token_id}`}
                >Wrap</Link>
            } else {
                return null
            }
        case 'wrapped':
            if (props.isOwner) {
                if (!conditions.length) {
                    return <button
                        className="btn btn-lg btn-yellow"
                        onClick={unwrap}
                    >UNWRAP & CLAIM
                    </button>
                } else {
                    return <div className="w-card__status">
                        <div>
                            <span className="small">Unwrap will be ready after Time or Value Unlock.</span>
                            <span>{conditions.join(', ')}</span>
                        </div>
                    </div>
                }
            } else {
                return null
            }
        default:
            return null
    }
}

TokenCardFooter.propTypes = {
    isOwner: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    ual: PropTypes.object.isRequired,
}

export default TokenCardFooter
