import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import {setCreateOfferModal, setCreateOfferToken} from '../../store/appSlice'

const CreateOfferButton = (props) => {
    const dispatch = useDispatch()

    return <li>
        <button
            onClick={() => {
                dispatch(setCreateOfferModal(true))
                dispatch(setCreateOfferToken(props.token))
            }}
        >
            Create offer
        </button>
    </li>
}

CreateOfferButton.propTypes = {
    token: PropTypes.number.isRequired,
}
export default withUAL(CreateOfferButton)
