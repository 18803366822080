import React, {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {withUAL} from 'ual-reactjs-renderer'
import icon_logo from '../static/pics/logo.svg'
import HeaderAccountInfo from './account/HeaderAccountInfo'
import ConnectButton from './wallet/ConnectButton'
import PropTypes from "prop-types";

const Header = (props) => {
    const userPanel = useMemo(() => {
        return props.ual.activeUser ? <HeaderAccountInfo ual={props.ual}/> : <ConnectButton ual={props.ual}/>
    }, [props.ual.activeUser])

    return <header className="s-header">
        <div className="container">
            <div>
                <Link className="s-header__logo" to="/">
                    <img src={icon_logo} alt="ENVELOP"/>
                </Link>
                <Link className="s-header__logo" to="/market">
                    Marketplace
                </Link>
            </div>
            {userPanel}
        </div>
    </header>
}

Header.propTypes = {
    ual: PropTypes.object,
}
export default withUAL(Header)
