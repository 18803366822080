import React from 'react'
import PropTypes from 'prop-types'
import TransferBackButton from '../buttons/TransferBackButton'
import CopyUrlButton from '../buttons/CopyUrlButton'
import TransferButton from '../buttons/TransferButton'
import CreateOfferButton from '../buttons/CreateOfferButton'
import CancelOfferButton from '../buttons/CancelOfferButton'

import icon_i_action from '../../static/pics/i-action.svg'

const TokenActions = (props) => {
    return <div className="w-card__action">
        <button className="btn-action">
            <img src={icon_i_action} alt=""/>
        </button>
        <ul className="list-action">
            {props.isOwner && <>
                {props.token.wrapped ? <TransferButton token={props.token}/> :
                    <TransferBackButton token={props.token}/>}
                {props.token.wrapped ? (
                    props.offer ? <CancelOfferButton offerId={props.offer.offer_id}/> :
                        <CreateOfferButton token={props.token.wrapped_token_id}/>
                ) : null}
            </>}
            <CopyUrlButton url={`${window.location.origin}/token/${props.token.wrapped_token_id}`}/>
        </ul>
    </div>
}

TokenActions.propTypes = {
    isOwner: PropTypes.bool.isRequired,
    offer: PropTypes.object,
    token: PropTypes.object.isRequired,
}
export default TokenActions
