import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {WaxApi} from '../utils/waxApi'

export const requestApprovedAccounts = createAsyncThunk(
    'account/requestApprovedAccounts',
    async (account) => {
        return await WaxApi.loadApprovedAccounts(account)
    }
)
export const requestBalance = createAsyncThunk(
    'account/requestBalance',
    async (account) => {
        return await WaxApi.loadBalance(account)
    }
)
export const requestExternalBalances = createAsyncThunk(
    'account/requestExternalBalances',
    async ({account, partnerTokens}) => {
        return await WaxApi.loadExternalBalances(account, partnerTokens)
    }
)
export const requestUserBalances = createAsyncThunk(
    'account/requestUserBalances',
    async (account) => {
        return await WaxApi.loadUserBalances(account)
    }
)

const initialState = {
    approvedAccounts: [],
    balance: null,
    externalBalances: [],
    userBalances: [],
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(requestApprovedAccounts.fulfilled, (state, action) => {
            state.approvedAccounts = action.payload
        })
        builder.addCase(requestBalance.fulfilled, (state, action) => {
            state.balance = action.payload
        })
        builder.addCase(requestExternalBalances.fulfilled, (state, action) => {
            console.log(action)
            state.externalBalances = action.payload
        })
        builder.addCase(requestUserBalances.fulfilled, (state, action) => {
            state.userBalances = action.payload
        })
    },
})

export const getApprovedAccounts = (state) => state.account.approvedAccounts
export const getBalance = (state) => state.account.balance
export const getExternalBalances = (state) => {
    let balances = {}
    for (let i = 0; i < state.account.externalBalances.length; i++) {
        balances[state.account.externalBalances[i].balance.split(' ')[1]] = state.account.externalBalances[i].balance
    }
    return balances
}
export const getUserBalances = (state) => {
    let balances = {}
    for (let i = 0; i < state.account.userBalances.length; i++) {
        balances[state.account.userBalances[i].quantity.split(' ')[1]] = state.account.userBalances[i].quantity
    }
    return balances
}

//export const {setWNFTs} = accountSlice.actions

export default accountSlice.reducer
