import React from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {addThousandSeparator} from '../../utils/functions'

import default_icon from '../../static/pics/coins/_default.png'

const TokenUnwraptFeeThresholdBlock = (props) => {
    const amount = props.accumulatedFee.split(' ')[0].split('.')
    const precision = amount.length === 2 ? amount[1].length : 0
    const threshold = new BigNumber(props.unwraptFeeThreshold / (10 ** precision))
    const feeSymbol = props.accumulatedFee.split(' ')[1]

    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">Unwrapt fee threshold</label>
        </div>
        <div className="field-row">
            <div className="field-control">
                <span>{threshold.gt(0) ? addThousandSeparator(threshold.toString()) : '—'}</span>
            </div>
            {feeSymbol ?
                <span className="field-unit">
                    <span className="i-coin"><img src={default_icon} alt=""/></span>
                    {feeSymbol}
                </span>
                :
                null
            }
        </div>
    </div>
}

TokenUnwraptFeeThresholdBlock.propTypes = {
    accumulatedFee: PropTypes.string.isRequired,
    unwraptFeeThreshold: PropTypes.number,
}
export default TokenUnwraptFeeThresholdBlock
