import React from 'react'
import PropTypes from 'prop-types'

const InputAddress = (props) => {
    return <input
        className="input-control"
        type="text"
        placeholder={'Paste address'}
        value={props.address}
        onChange={(e) => {
            props.setAddress(e.target.value)
        }}
    />
}

InputAddress.propTypes = {
    address: PropTypes.string.isRequired,
    setAddress: PropTypes.func.isRequired,
}
export default InputAddress
