import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import {setTransferModal, setTransferToken} from '../../store/appSlice'

const TransferButton = (props) => {
    const dispatch = useDispatch()

    return <li>
        <button
            onClick={() => {
                dispatch(setTransferModal(true))
                dispatch(setTransferToken(props.token))
            }}
        >
            Transfer
        </button>
    </li>
}

TransferButton.propTypes = {
    token: PropTypes.object.isRequired,
}
export default withUAL(TransferButton)
