import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {setDepositModal, setWithdrawModal} from '../../store/appSlice'

const ProfileAccountInfo = (props) => {
    const dispatch = useDispatch()

    const balancesList = props.balances.map((item) => <div key={item.quantity.split(' ')[1]}>{item.quantity}</div>)

    return <div className="db-section">
        <div className="divider right short"></div>
        <div className="container">
            <div className="c-header">
                <div className="h3">{props.accountName} balances</div>
                {balancesList}
            </div>
            {props.isOwner && <div className="lp-list__footer">
                <div>
                    <a
                        onClick={() => {dispatch(setDepositModal(true))}}
                        style={{cursor: "pointer"}}
                    >Deposit</a> | <a
                        onClick={() => {dispatch(setWithdrawModal(true))}}
                        style={{cursor: "pointer"}}
                    >Withdraw</a>
                </div>
            </div>}
        </div>
    </div>
}

ProfileAccountInfo.propTypes = {
    accountName: PropTypes.string.isRequired,
    balances: PropTypes.array,
    isOwner: PropTypes.bool,
}
export default ProfileAccountInfo
