import React from 'react'
import Tippy from '@tippyjs/react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {getTokenCollateralById} from '../../store/profileSlice'
import {setAddCollateralModal, setAddCollateralToken} from '../../store/appSlice'
import {getToken, getTokenCollateral} from '../../store/tokenSlice'

import default_icon from '../../static/pics/coins/_default.png'
import icon_i_plus from '../../static/pics/i-plus.svg'

const TokenCollateralBlock = (props) => {
    let tokenId = props.tokenId
    const token = useSelector(getToken)
    const detailsCollateral = useSelector(getTokenCollateral)
    const shortCollateral = useSelector(getTokenCollateralById(tokenId))

    const dispatch = useDispatch()

    let tokenCollateral = []
    if (props.isShortData) {
        tokenCollateral = shortCollateral
    } else {
        tokenCollateral = detailsCollateral
        tokenId = token.wrapped_token_id
    }
    if (!tokenId) {
        return null
    }

    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">
                Collateral
                <Tippy
                    content={'Assets with which your wrapped NFT is provided'}
                    appendTo={document.getElementsByClassName("wrapper")[0]}
                    trigger='mouseenter'
                    interactive={false}
                    arrow={false}
                    maxWidth={260}

                >
                    <span className="i-tip"></span>
                </Tippy>
            </label>
        </div>
        <div className="field-row">
            <div className="field-control field-collateral">
                <div className="coins">
                    {tokenCollateral.map(item => <span key={item.symbol} className="i-coin"><img
                        src={default_icon} alt=""/></span>)}
                </div>
                <div className="sum">
                    {tokenCollateral.length ? `${tokenCollateral.length} ${(tokenCollateral.length === 1 ? 'asset' : 'assets')}` : 'no assets'}
                </div>
                {tokenCollateral.length ? <div className="field-collateral__details">
                        <div className="inner">
                            <table>
                                <tbody>
                                {tokenCollateral.map(item => <tr key={item.symbol}>
                                        <td>
                                            <span className="unit-sum">{item.amount}</span>
                                        </td>
                                        <td><span className="field-unit"><span
                                            className="i-coin"><img
                                            src={default_icon}
                                            alt=""/></span>{item.symbol}</span>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            <button
                className="btn-add"
                onClick={() => {
                    dispatch(setAddCollateralModal(true))
                    dispatch(setAddCollateralToken(tokenId))
                }}
            >
                <img src={icon_i_plus} alt=""/>
            </button>
        </div>
    </div>
}

TokenCollateralBlock.propTypes = {
    isShortData: PropTypes.bool,
    tokenId: PropTypes.number,
}
export default TokenCollateralBlock
