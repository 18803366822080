import React from 'react'
import PropTypes from 'prop-types'
import CopyBlock from '../CopyBlock'

const CopyUrlButton = (props) => {
    return <li>
        <CopyBlock
            text={props.url}
            hideText={true}
            title={'Copy NFT URL'}
        />
    </li>
}

CopyUrlButton.propTypes = {
    url: PropTypes.string.isRequired,
}
export default CopyUrlButton
