import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import AddCollateralModal from '../modals/AddCollateralModal'
import {getAddCollateralModal, getDepositModal, getTransferModal} from '../../store/appSlice'
import DepositModal from '../modals/DepositModal'
import TransferModal from '../modals/TransferModal'
import {getMoreOffers, getNextKeyOffers, getOffers, requestOffers, resetMarket} from '../../store/offerSlice'
import TokensList from '../tokens/TokensList'

const Market = (props) => {
    const addCollateralModal = useSelector(getAddCollateralModal)
    const depositModal = useSelector(getDepositModal)
    const moreOffers = useSelector(getMoreOffers)
    const nextKey = useSelector(getNextKeyOffers)
    const offers = useSelector(getOffers)
    const transferModal = useSelector(getTransferModal)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestOffers())
        return () => {
            dispatch(resetMarket())
        }
    }, [])

    const offersList = useMemo(() => {
        return <TokensList
            loadMore={() => {dispatch(requestOffers(nextKey))}}
            more={moreOffers}
            title={'wNFTs on sale'}
            tokens={offers}
            type={'offers'}
        />
    }, [offers])

    return <>
        <main className="s-main">
            {offersList}
        </main>
        {addCollateralModal && <AddCollateralModal
            market={true}
            ual={props.ual}
        />}
        {depositModal && <DepositModal
            ual={props.ual}
        />}
        {transferModal && <TransferModal
            ual={props.ual}
        />}
    </>
}

Market.propTypes = {
    ual: PropTypes.object,
}
export default withUAL(Market)
