import React from 'react'
import ReactDOM from 'react-dom/client'
import {UALProvider} from 'ual-reactjs-renderer'
import {Anchor} from 'ual-anchor'
import {Wax} from '@eosdacio/ual-wax'
import { Provider } from 'react-redux'
import store from './store/store'
import './static/css/styles.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const waxChain = {
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
//    chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',  //testnet
    rpcEndpoints: [{
        protocol: 'https',
        host: 'wax.greymass.com',
//        host: 'testnet.waxsweden.org',
//        host: 'testnet.wax.pink.gg',
        port: '443',
    }]
}
const anchor = new Anchor([waxChain], {
    appName: 'Envelop Protocol',
})
const wax = new Wax([waxChain], {})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <UALProvider appName={'Envelop Protocol'} chains={[waxChain]} authenticators={[wax, anchor]}>
            <Provider store={store}>
                <App/>
            </Provider>
        </UALProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()