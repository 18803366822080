import React, {useEffect, useRef, useState} from 'react'
import {isFirefox} from 'react-device-detect'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import {useNavigate} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import SelectCoin from '../forms/SelectCoin'
import InputAmount from '../forms/InputAmount'
import {getPartnerTokens} from '../../store/appSlice'
import {monthsNames, PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {addThousandSeparator, sendingTransaction, sleep, unixtimeToStr} from '../../utils/functions'
import {resetToken} from '../../store/tokenSlice'
import TokenCollateralBlock from './TokenCollateralBlock'

import default_icon from '../../static/pics/coins/_default.png'

const TokenDetailsData = (props) => {
    const [inputFeesToken, setInputFeesToken] = useState({value: '', decimals: 0, symbol: ''})
    const [inputFeesUnlockToken, setInputFeesUnlockToken] = useState({value: '', decimals: 0, symbol: ''})
    const [inputUnlockTime, setInputUnlockTime] = useState('')
    const [inputRoyalties, setInputRoyalties] = useState('')
    const [inputRoyaltiesReceiver, setInputRoyaltiesReceiver] = useState('')
    const [feesChecked, setFeesChecked] = useState(false)
    const [unlockFeeChecked, setUnlockFeeChecked] = useState(false)
    const [unlockTimeChecked, setUnlockTimeChecked] = useState(false)
    const partnerTokens = useSelector(getPartnerTokens)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const feesRef = useRef()
    const unlockTimeRef = useRef()

    useEffect(() => {
        setInputFeesUnlockToken((prevState) => ({...inputFeesToken, value: prevState.value}))
    }, [inputFeesToken])

    const addDays = (num) => {
        const output = new Date()
        output.setDate(output.getDate() + num)
        return output
    }
    const addedDate = () => {
        const daysToAdd = parseInt(inputUnlockTime) || 0

        if (daysToAdd > 365) {
            return '365 days max'
        }

        const output = addDays(daysToAdd)
        return `${output.getDate()} ${monthsNames[output.getMonth()]} ${output.getFullYear()}`
    }
    const checkRoyalty = () => {
        let fee
        if (!feesChecked) {
            fee = new BigNumber(0)
        } else {
            fee = new BigNumber(inputFeesToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputFeesToken.value)
        }
        const royalty = new BigNumber(inputRoyalties).isNaN() ? new BigNumber(0) : new BigNumber(inputRoyalties)
        if (royalty.gt(0) && !fee.gt(0)) {
            return <span className="field-error">ENTER FEE</span>
        } else {
            return <span className="field-unit">%</span>
        }
    }
    const checkRoyaltyReceiver = () => {
        if (!inputRoyalties && inputRoyaltiesReceiver) {
            return <span className="field-error">
				ENTER ROYALTY<br/>
			</span>
        }

        const royalty = new BigNumber(inputRoyalties).isNaN() ? new BigNumber(0) : new BigNumber(inputRoyalties)
        if (royalty.gt(0) && !inputRoyaltiesReceiver) {
            return <span className="field-error">
				ENTER RECIPIENT<br/>
			</span>
        }

        if (inputRoyaltiesReceiver.length > 12) {
            return <span className="field-error">
					Wrong format<br/>
				</span>
        } else {
            return ''
        }
    }
    const checkUnlockFees = () => {
        if (!unlockFeeChecked) {
            return ''
        }

        let fee
        if (!feesChecked) {
            fee = new BigNumber(0)
        } else {
            fee = new BigNumber(inputFeesToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputFeesToken.value)
        }
        let unlock_fee
        if (!unlockFeeChecked) {
            unlock_fee = new BigNumber(0)
        } else {
            unlock_fee = new BigNumber(inputFeesUnlockToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputFeesUnlockToken.value)
        }

        if (unlock_fee.gt(0) && fee.eq(0)) {
            return <span className="field-error">
                ENTER FEE
            </span>
        }
        return <span className="field-unit">
            {inputFeesToken.symbol}
        </span>
    }
    const isSubmitDisabled = () => {
        let fee
        if (!feesChecked) {
            fee = new BigNumber(0)
        } else {
            fee = new BigNumber(inputFeesToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputFeesToken.value)
        }
        let unlock_fee
        if (!unlockFeeChecked) {
            unlock_fee = new BigNumber(0)
        } else {
            unlock_fee = new BigNumber(inputFeesUnlockToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputFeesUnlockToken.value)
        }
        const royalty = new BigNumber(inputRoyalties).isNaN() ? new BigNumber(0) : new BigNumber(inputRoyalties)

        if (inputRoyaltiesReceiver) {
            if (!inputRoyalties) {
                return true
            }
        } else {
            if (royalty.gt(0)) {
                return true
            }
        }

        if (Number(inputUnlockTime) > 365) {
            return true
        }
        if (royalty.gt(0) && !fee.gt(0)) {
            return true
        }
        if (unlock_fee.gt(0) && !fee.gt(0)) {
            return true
        }

        return false;
    }
    const unwrap = () => {
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'unwrap',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        wrapped_token_id: props.token.wrapped_token_id,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            navigate(`/profile/${props.ual.activeUser.accountName}`)
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const wrap = () => {
        let unwrapAfter = 0
        if (parseInt(inputUnlockTime)) {
            const output = new Date()
            output.setDate(output.getDate() + parseInt(inputUnlockTime))
            unwrapAfter = Math.floor(output.getTime() / 1000)
        }
        let transferFee = '0 WAX'
        let feeValue = new BigNumber(inputFeesToken.value)
        if (!feeValue.isNaN()) {
            transferFee = `${feeValue.toFixed(inputFeesToken.decimals)} ${inputFeesToken.symbol}`
        }
        let unwraptFeeThreshold = 0
        let feesUnlockValue = new BigNumber(inputFeesUnlockToken.value)
        if (!feesUnlockValue.isNaN()) {
            unwraptFeeThreshold = BigInt(feesUnlockValue.toFixed(inputFeesUnlockToken.decimals) * (10 ** inputFeesUnlockToken.decimals))
        }
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'wrap',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        wrapped_token_id: parseInt(props.token.wrapped_token_id),
                        unwrap_after: unwrapAfter,
                        royalty_beneficiary: inputRoyaltiesReceiver,
                        transfer_fee: transferFee,
                        royalty_percent: parseInt(inputRoyalties) || 0,
                        unwrapt_fee_threshold: unwraptFeeThreshold,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(resetToken())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    const conditions = []
    if (props.token.unwrap_after > Date.now() / 1000) {
        conditions.push(unixtimeToStr(props.token.unwrap_after * 1000))
    }
    let unwraptFeeThreshold = new BigNumber(props.token.unwrapt_fee_threshold)
    let accumulatedFee = new BigNumber(props.token.accumulated_fee.split(' ')[0])
    const decimals = props.token.accumulated_fee.split(' ')[0].split('.')
    if (decimals.length === 2) {
        unwraptFeeThreshold /= 10 ** decimals[1].length
    }
    if (unwraptFeeThreshold && accumulatedFee && accumulatedFee.lt(unwraptFeeThreshold)) {
        conditions.push(`${addThousandSeparator(unwraptFeeThreshold.toString())} ${props.token.accumulated_fee.split(' ')[1]}`)
    }

    if (props.token.wrapped) {
        return <>
            <div className="w-card__param">
                <div className="form-row">
                    <TokenCollateralBlock/>
                </div>
                <div className="form-row">
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">
                                <span>Collected Fees</span>
                                <Tippy
                                    content={'Accumulated transfer fee on this wNFT balance'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <div className="field-control">
                                <span>{parseFloat(props.token.accumulated_fee) > 0 ? addThousandSeparator(props.token.accumulated_fee.split(' ')[0]) : '—'}</span>
                            </div>
                            {parseFloat(props.token.accumulated_fee) > 0 && <span className="field-unit">
                                <span className="i-coin"><img src={default_icon} alt=""/></span>
                                {props.token.accumulated_fee.split(' ')[1]}
                            </span>}
                        </div>
                    </div>
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">
                                <span>Transfer Fee</span>
                                <Tippy
                                    content={'Amount of fee for the transfer of wrapped NFT'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <div className="field-control">
                                <span>{parseFloat(props.token.transfer_fee) > 0 ? addThousandSeparator(props.token.transfer_fee.split(' ')[0]) : '—'}</span>
                            </div>
                            {parseFloat(props.token.transfer_fee) > 0 && <span className="field-unit">
                                <span className="i-coin"><img src={default_icon} alt=""/></span>
                                {props.token.transfer_fee.split(' ')[1]}
                            </span>}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">Royalty
                                <Tippy
                                    content={'Percent of royalty from the transfer fee amount'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <div className="field-control">
                                {props.token.royalty_percent || '—'}
                            </div>
                            {props.token.royalty_percent ? <span className="field-unit">%</span> : null}
                        </div>
                    </div>
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">Royalty recipient
                                <Tippy
                                    content={'Address of royalty income reciever'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <div className="field-control">
                                {props.token.royalty_beneficiary.length > 0 ? props.token.royalty_beneficiary : '—'}
                            </div>
                        </div>
                    </div>
                </div>
                {!props.isOwner && <div className="form-row">
                    <div className="field-wrap">
                        <div className="field-row">
                            <span>
                                Token owner:
                                {' '}
                                <Link to={`/profile/${props.token.owner}`}>{props.token.owner}</Link>
                            </span>
                        </div>
                    </div>
                </div>
                }
            </div>
            {props.isOwner && (conditions.length ?
                    <div className="w-card__status">
                        <div>
                            <span className="small">Unwrap will be ready after Time or Value Unlock.</span>
                            <span>{conditions.join(', ')}</span>
                        </div>
                    </div>
                    :
                    <button
                        className="btn btn-lg btn-yellow"
                        onClick={unwrap}
                        disabled={!!conditions.length}
                    >UNWRAP & CLAIM
                    </button>
            )}
        </>
    } else {
        if (props.isOwner) {
            return <div className="w-card__param">
                <div className="form-row">
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <div className="field-label">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={feesChecked}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFeesChecked(true)
                                                setTimeout(feesRef.focus, 1)
                                            } else {
                                                setFeesChecked(false)
                                                setInputFeesToken((prevState) => ({...prevState, value: ''}))
                                                setInputRoyalties('')
                                                setInputRoyaltiesReceiver('')
                                                setUnlockFeeChecked(false)
                                                setInputFeesUnlockToken((prevState) => ({...prevState, value: ''}))
                                            }
                                        }}
                                    />
                                    <span className="check"> </span>
                                    <span>Fees</span>
                                </label>
                                <Tippy
                                    content={'Amount of transfer fee. Sender has to pay this fee to transfer wNFT. So that fee amount must be approved to this contract before any wNFT transfer (marketplace trading)'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </div>
                        </div>
                        <div
                            className="field-row"
                            onClick={() => {
                                if (!feesChecked) {
                                    setFeesChecked(true)
                                    setTimeout(feesRef.focus, 1);
                                }
                            }}
                        >
                            <InputAmount
                                token={inputFeesToken}
                                setToken={setInputFeesToken}
                            />
                            {partnerTokens.length && <SelectCoin
                                partnerTokens={partnerTokens}
                                token={inputFeesToken}
                                setToken={setInputFeesToken}
                            />}
                        </div>
                    </div>
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <div className="field-label">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={unlockTimeChecked}
                                        onChange={(e) => {
                                            setUnlockTimeChecked(e.target.checked)
                                        }}
                                    />
                                    <span className="check"></span>
                                    <span>Time Unlock</span>
                                </label>
                                <Tippy
                                    content={'Unwrap will be available only after this date'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </div>
                            <span className="field-data">{addedDate()}</span>
                        </div>
                        <div
                            className="field-row"
                            onClick={() => {
                                if (!unlockTimeChecked) {
                                    setUnlockTimeChecked(true)
                                }
                            }}
                        >
                            <input
                                className="field-control"
                                placeholder="0"
                                type="text"
                                style={{pointerEvents: isFirefox ? 'none' : undefined}}
                                ref={unlockTimeRef}
                                value={inputUnlockTime}
                                onChange={(e) => {
                                    const value = e.target.value.replaceAll(' ', '');
                                    if (value === '' || isNaN(parseInt(value))) {
                                        setInputUnlockTime('')
                                        return
                                    }
                                    if (!isNaN(addDays(parseInt(value)).getTime())) {
                                        setInputUnlockTime(`${parseInt(value)}`)
                                    }
                                }}
                                disabled={!unlockTimeChecked}
                            />
                            <span className="field-unit">days</span>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">{'Royalty recipient'}
                                <Tippy
                                    content={'Address of royalty income reciever'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <input
                                className="field-control"
                                placeholder="wallet"
                                type="text"
                                value={inputRoyaltiesReceiver}
                                onChange={(e) => {
                                    if (e.target.value.match(/^[a-zA-Z.1-5]+$/) || e.target.value === '') {
                                        setInputRoyaltiesReceiver(e.target.value)
                                    }
                                }}
                            />
                            {checkRoyaltyReceiver()}
                        </div>
                    </div>
                    <div className="field-wrap col-sm-6">
                        <div className="field-row">
                            <label className="field-label">Royalty
                                <Tippy
                                    content={'Percent of royalty from the transfer fee amount'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </label>
                        </div>
                        <div className="field-row">
                            <input
                                className="field-control"
                                placeholder="0"
                                type="text"
                                value={inputRoyalties}
                                onChange={(e) => {
                                    const value = e.target.value.replaceAll(' ', '');
                                    if (value === '' || isNaN(parseInt(value))) {
                                        setInputRoyalties('')
                                        return
                                    }
                                    if (parseInt(value) <= 100) {
                                        setInputRoyalties(value)
                                    }
                                }}
                            />
                            {checkRoyalty()}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="field-wrap col-sm-6"
                         onClick={() => {
                             setFeesChecked(true)
                         }}
                    >
                        <div className="field-row">
                            <div className="field-label">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={unlockFeeChecked}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFeesChecked(true)
                                            }
                                            setUnlockFeeChecked(e.target.checked)
                                        }}
                                    />
                                    <span className="check"> </span><span>Fee Unlock</span>
                                </label>
                                <Tippy
                                    content={'Unwrap will be available only after accumulated transfer fee will achieve this amount'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip"></span>
                                </Tippy>
                            </div>
                        </div>
                        <div
                            className="field-row"
                            onClick={() => {
                                if (!unlockFeeChecked) {
                                    setUnlockFeeChecked(true)
                                }
                            }}
                        >
                            <InputAmount
                                token={inputFeesUnlockToken}
                                setToken={setInputFeesUnlockToken}
                            />
                            {checkUnlockFees()}
                        </div>
                    </div>
                    <div className="field-wrap col-sm-6"/>
                </div>
                <div className="form-submit">
                    <button
                        className="btn"
                        disabled={isSubmitDisabled()}
                        onClick={wrap}
                    >WRAP TOKEN
                    </button>
                </div>
            </div>
        } else {
            return <div className="w-card__param">
                <div className="form-row">
                    <div className="field-wrap">
                        <div className="field-row">
                            <span>
                                Token owner:
                                {' '}
                                <Link to={`/profile/${props.token.owner}`}>{props.token.owner}</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

TokenDetailsData.propTypes = {
    isOwner: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
    ual: PropTypes.object.isRequired,
}
export default TokenDetailsData
