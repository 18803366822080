import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AtomicAssetsApi} from '../utils/atomicAssetsApi'
import {WaxApi} from '../utils/waxApi'

export const requestMetadata = createAsyncThunk(
    'offer/requestMetadata',
    async (id) => {
        return {id, data: await AtomicAssetsApi.getAssetMetadata(id)}
    }
)
export const requestTokenCollateral = createAsyncThunk(
    'offer/requestTokenCollateral',
    async (id) => {
        return {id, collateral: (await WaxApi.loadTokenCollateral(id)).rows}
    }
)
export const requestOffers = createAsyncThunk(
    'offer/requestOffers',
    async (nextKey) => {
        let offers = await WaxApi.loadMarketOffers(nextKey)
        for (let i = 0; i < offers.rows.length; i++) {
            const token = await WaxApi.loadToken(offers.rows[i].wrapped_token_id)
            offers.rows[i] = {...offers.rows[i], ...token}
        }
        return offers
    }
)

const initialState = {
    collateral: {},
    metadata: {},
    moreOffers: false,
    nextKey: 0,
    offers: null,
}

export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        resetMarket: (state) => {
            state.collateral = {}
            state.metadata = {}
            state.offers = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestMetadata.fulfilled, (state, action) => {
            state.metadata = {...state.metadata, [action.payload.id]: action.payload.data}
        })
        builder.addCase(requestTokenCollateral.fulfilled, (state, action) => {
            state.collateral = {...state.collateral, [action.payload.id]: action.payload.collateral}
        })
        builder.addCase(requestOffers.fulfilled, (state, action) => {
            if (state.offers) {
                state.offers.push(...action.payload.rows)
            } else {
                state.offers = action.payload.rows
            }
            state.moreOffers = action.payload.more
            state.nextKey = action.payload.next_key
        })
    },
})

export const getMoreOffers = (state) => state.offer.moreOffers
export const getNextKeyOffers = (state) => state.offer.nextKey
export const getOfferCollateralById = (id) => (state) => {
    const collateral = state.offer.collateral[id]
    if (!collateral) {
        return []
    }
    let result = []
    for (let i = 0; i < collateral.length; i++) {
        result[i] = {
            ...collateral,
            amount: collateral[i].quantity.split(' ')[0],
            symbol: collateral[i].quantity.split(' ')[1],
        }
    }
    return result
}
export const getOfferMetadataById = (id) => (state) => state.offer.metadata[id] || null
export const getOffers = (state) => state.offer.offers

export const {resetMarket} = offerSlice.actions

export default offerSlice.reducer
