import React, {useEffect, useState} from 'react'
import Tippy from '@tippyjs/react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {getPartnerTokens, setWithdrawModal} from '../../store/appSlice'
import InputAmount from '../forms/InputAmount'
import SelectCoin from '../forms/SelectCoin'
import {balanceToFixed, sendingTransaction, sleep} from '../../utils/functions'
import {PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {getUserBalances, requestBalance, requestUserBalances} from '../../store/accountSlice'
import {requestBalances} from '../../store/profileSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const WithdrawModal = (props) => {
    const [inputWithdrawToken, setInputWithdrawToken] = useState({value: '', decimals: 0, symbol: ''})
    const partnerTokens = useSelector(getPartnerTokens)
    const userBalances = useSelector(getUserBalances)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestUserBalances(props.ual.activeUser.accountName))
    }, [])

    const withdraw = () => {
        const amount = new BigNumber(inputWithdrawToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputWithdrawToken.value)
        if (!amount.gt(0)) {
            return
        }
        const quantity = balanceToFixed(`${amount} ${inputWithdrawToken.symbol}`, inputWithdrawToken.decimals)
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'withdraw',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        owner: props.ual.activeUser.accountName,
                        quantity: quantity,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestBalance(props.ual.activeUser.accountName))
            dispatch(requestBalances(props.ual.activeUser.accountName))
            dispatch(requestUserBalances(props.ual.activeUser.accountName))
            dispatch(setWithdrawModal(false))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const closePopup = () => {
        dispatch(setWithdrawModal(false))
    }
    const isDisabled = () => {
        if (!inputWithdrawToken.value) {
            return true
        }
        const value = new BigNumber(inputWithdrawToken.value)
        if (!value.gt(0)) {
            return true
        }
        const balance = new BigNumber(userBalances[inputWithdrawToken.symbol].split(' ')[0])
        if (value.gt(balance)) {
            return true
        }
        return false
    }

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation()
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Withdraw</div>
                            <p></p>
                        </div>
                        <div className="c-add__coins">
                            <label className="input-label">
                                Amount
                                <Tippy
                                    content={'Maximum and allowanced amount of tokens which you can add to collateral of wrapped nft'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip black"></span>
                                </Tippy>
                            </label>
                            <div className="c-add__max">
                                <div>
                                    {userBalances[inputWithdrawToken.symbol] ? <>
                                            <span>Max:</span>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    if (new BigNumber(userBalances[inputWithdrawToken.symbol]).eq(0)) {
                                                        return
                                                    }
                                                    setInputWithdrawToken(prevState => ({...prevState, value: userBalances[inputWithdrawToken.symbol].split(' ')[0]}))
                                                }}
                                            >
                                                {userBalances[inputWithdrawToken.symbol]}
                                            </button>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="c-add__form">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="input-group">
                                                <InputAmount
                                                    className="input-control"
                                                    token={inputWithdrawToken}
                                                    setToken={setInputWithdrawToken}
                                                    disabled={!userBalances[inputWithdrawToken.symbol]}
                                                />
                                                <SelectCoin
                                                    partnerTokens={partnerTokens}
                                                    token={inputWithdrawToken}
                                                    setToken={setInputWithdrawToken}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn"
                                                disabled={isDisabled()}
                                                onClick={withdraw}
                                            >Withdraw
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

WithdrawModal.propTypes = {
    profile: PropTypes.bool,
    ual: PropTypes.object.isRequired,
}
export default WithdrawModal
