import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import {MARKETPLACE_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {sendingTransaction, sleep} from '../../utils/functions'
import {resetToken} from '../../store/tokenSlice'
import {requestWNFTs} from '../../store/profileSlice'
import {resetMarket} from '../../store/offerSlice'

const CancelOfferButton = (props) => {
    const dispatch = useDispatch()

    const cancelOffer = () => {
        const tr = {
            actions: [
                {
                    account: MARKETPLACE_CONTRACT,
                    name: 'canceloffer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        offer_id: props.offerId,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestWNFTs(props.ual.activeUser.accountName))
            dispatch(resetToken())
            dispatch(resetMarket())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <li>
        <button
            onClick={cancelOffer}
        >
            Cancel offer
        </button>
    </li>
}

CancelOfferButton.propTypes = {
    offerId: PropTypes.number.isRequired,
    ual: PropTypes.object,
}
export default withUAL(CancelOfferButton)
