import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Blockies from 'react-blockies'
import {getBalance, requestApprovedAccounts, requestBalance} from '../../store/accountSlice'
import {balanceToFixed} from '../../utils/functions'
import CopyBlock from '../CopyBlock'

const HeaderAccountInfo = (props) => {
    const balance = useSelector(getBalance)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.ual.activeUser) {
            dispatch(requestBalance(props.ual.activeUser.accountName))
            dispatch(requestApprovedAccounts(props.ual.activeUser.accountName))
        }
    }, [props.ual.activeUser])

    return <div className="s-user">
        <button
            className="btn btn-sm btn-outline"
            style={{marginRight: '15px'}}
            onClick={props.ual.logout}
        >
            {'disconnect'}
        </button>
        <div className="s-user__data">
            <div className="info">
                {balance ? balanceToFixed(balance, 4) : '...'}
            </div>
            <CopyBlock
                text={props.ual.activeUser.accountName}
            />
        </div>
        <div className="s-user__avatar">
            <Link to={`/profile/${props.ual.activeUser.accountName}`}>
                <div className="img">
                    <Blockies
                        seed={props.ual.activeUser.accountName}
                        size={5}
                        scale={10}
                        color="#141616"
                        bgColor="#4afebf"
                        spotColor="#ffffff"
                    />
                </div>
            </Link>
        </div>
    </div>
}

HeaderAccountInfo.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default HeaderAccountInfo
