export const ATOMICASSETS_CONTRACT = 'atomicassets'
export const ATOMICASSETS_LAYOUT_CONTRACT = 'envelopatom1'
export const COPIED_HINT_TIMEOUT = 3000
export const PROTOCOL_CONTRACT = 'envelopwrap1'
export const MARKETPLACE_CONTRACT = 'envelopmrkt1'
export const SLEEP_AFTER_TRANSACTION = 3000
export const TOKENS_PER_PAGE = 9
//export const WAX_RPC_ENDPOINT = 'https://testnet.waxsweden.org'
//export const WAX_RPC_ENDPOINT = 'https://testnet.wax.pink.gg'
export const WAX_RPC_ENDPOINT = 'https://wax.pink.gg'

export const monthsNames = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dec']
