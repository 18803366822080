import React, {useEffect, useState} from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {getPartnerTokens, setDepositModal} from '../../store/appSlice'
import InputAmount from '../forms/InputAmount'
import SelectCoin from '../forms/SelectCoin'
import {PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {balanceToFixed, sendingTransaction, sleep} from '../../utils/functions'
import {
    getBalance, getExternalBalances,
    getUserBalances,
    requestBalance,
    requestExternalBalances,
    requestUserBalances
} from '../../store/accountSlice'
import {requestBalances} from '../../store/profileSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const DepositModal = (props) => {
    const [inputDepositToken, setInputDepositToken] = useState({value: '', decimals: 0, symbol: ''})
    const balance = useSelector(getBalance)
    const externalBalances = useSelector(getExternalBalances)
    const partnerTokens = useSelector(getPartnerTokens)
    const userBalances = useSelector(getUserBalances)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestBalance(props.ual.activeUser.accountName))
        dispatch(requestUserBalances(props.ual.activeUser.accountName))
        dispatch(requestExternalBalances({account: props.ual.activeUser.accountName, partnerTokens}))
    }, [])

    const closePopup = () => {
        dispatch(setDepositModal(false))
    }
    const submitHandler = (e) => {
        e.preventDefault()
        const amount = new BigNumber(inputDepositToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputDepositToken.value)
        if (!amount.gt(0)) {
            return
        }
        const quantity = balanceToFixed(`${amount} ${inputDepositToken.symbol}`, inputDepositToken.decimals)
        const tr = {
            actions: [
                {
                    account: inputDepositToken.partner_token_contract,
                    name: 'transfer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        from: props.ual.activeUser.accountName,
                        to: PROTOCOL_CONTRACT,
                        quantity: quantity,
                        memo: 'deposit',
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestBalance(props.ual.activeUser.accountName))
            dispatch(requestUserBalances(props.ual.activeUser.accountName))
            if (props.profile) {
                dispatch(requestBalances(props.ual.activeUser.accountName))
            }
            dispatch(setDepositModal(false))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    let depositAvailableBalance = inputDepositToken.partner_token_contract ? (
        inputDepositToken.partner_token_contract === 'eosio.token' ? balance : externalBalances[inputDepositToken.symbol]
    ) : null

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation()
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Deposit</div>
                            <p>You can deposit assets to contract for transfer your wrapped NFT or for add collateral.
                                Use list of approved tokens.</p>
                        </div>
                        <div className="c-add__coins">
                            <label className="input-label">
                                Amount
                            </label>
                            {depositAvailableBalance ?
                                <div className="c-add__max">
                                    <div>
                                        {userBalances[inputDepositToken.symbol] ? <>
                                                <span>Max:</span>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        if (new BigNumber(depositAvailableBalance).eq(0)) {
                                                            return
                                                        }
                                                        setInputDepositToken(prevState => ({
                                                            ...prevState,
                                                            value: depositAvailableBalance.split(' ')[0]
                                                        }))
                                                    }}
                                                >
                                                    {depositAvailableBalance}
                                                </button>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                :
                                <div className="c-add__max">
                                    <div>
                                        <span>You haven&apos;t this token</span>
                                    </div>
                                </div>
                            }
                            <div className="c-add__form">
                                <form
                                    onSubmit={submitHandler}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="input-group">
                                                <InputAmount
                                                    className="input-control"
                                                    token={inputDepositToken}
                                                    setToken={setInputDepositToken}
                                                />
                                                <SelectCoin
                                                    partnerTokens={partnerTokens}
                                                    token={inputDepositToken}
                                                    setToken={setInputDepositToken}
                                                />
                                            </div>
                                            {depositAvailableBalance && parseFloat(depositAvailableBalance) < parseFloat(inputDepositToken.value) ?
                                                <div className="link-about text-red">
                                                    Insufficient balance
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn"
                                                disabled={!(inputDepositToken.value && new BigNumber(inputDepositToken.value).gt(0))}
                                            >
                                                Deposit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

DepositModal.propTypes = {
    profile: PropTypes.bool,
    ual: PropTypes.object.isRequired,
}
export default DepositModal
