import React, {useEffect, useState} from 'react'
import Tippy from '@tippyjs/react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {
    getAddCollateralToken,
    getPartnerTokens,
    setAddCollateralModal,
    setAddCollateralToken,
    setDepositModal
} from '../../store/appSlice'
import InputAmount from '../forms/InputAmount'
import SelectCoin from '../forms/SelectCoin'
import {balanceToFixed, sendingTransaction, sleep} from '../../utils/functions'
import {PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {getUserBalances, requestUserBalances} from '../../store/accountSlice'
import {requestBalances, requestWNFTs} from '../../store/profileSlice'
import {requestTokenCollateral as requestProfileTokenCollateral} from '../../store/profileSlice'
import {requestTokenCollateral} from '../../store/tokenSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const AddCollateralModal = (props) => {
    const [inputCollateralToken, setInputCollateralToken] = useState({value: '', decimals: 0, symbol: ''})
    const partnerTokens = useSelector(getPartnerTokens)
    const tokenId = useSelector(getAddCollateralToken)
    const userBalances = useSelector(getUserBalances)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestUserBalances(props.ual.activeUser.accountName))
    }, [])

    const addCollateral = () => {
        const amount = new BigNumber(inputCollateralToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputCollateralToken.value)
        if (!amount.gt(0)) {
            return
        }
        const quantity = balanceToFixed(`${amount} ${inputCollateralToken.symbol}`, inputCollateralToken.decimals)
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'tocollateral',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        username: props.ual.activeUser.accountName,
                        wrapped_token_id: tokenId,
                        quantity: quantity,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestUserBalances(props.ual.activeUser.accountName))
            if (props.profile) {
                dispatch(requestWNFTs(props.ual.activeUser.accountName))
                dispatch(requestBalances(props.ual.activeUser.accountName))
            } else if (props.market) {
                dispatch(requestProfileTokenCollateral(tokenId))
            } else {
                dispatch(requestTokenCollateral(tokenId))
            }
            dispatch(setAddCollateralModal(false))
            dispatch(setAddCollateralToken(null))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const closePopup = () => {
        dispatch(setAddCollateralModal(false))
    }
    const isDisabled = () => {
        if (!inputCollateralToken.value) {
            return true
        }
        const value = new BigNumber(inputCollateralToken.value)
        if (!value.gt(0)) {
            return true
        }
        const balance = new BigNumber(userBalances[inputCollateralToken.symbol].split(' ')[0])
        if (value.gt(balance)) {
            return true
        }
        return false
    }

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation()
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Add Collateral</div>
                            <p>You can add assets to collateral of your wrapped NFT. Use list of approved tokens.</p>
                        </div>
                        <div className="c-add__coins">
                            <label className="input-label">
                                Amount
                                <Tippy
                                    content={'Maximum and allowanced amount of tokens which you can add to collateral of wrapped nft'}
                                    appendTo={document.getElementsByClassName("wrapper")[0]}
                                    trigger='mouseenter'
                                    interactive={false}
                                    arrow={false}
                                    maxWidth={260}
                                >
                                    <span className="i-tip black"></span>
                                </Tippy>
                            </label>
                            <div className="c-add__max">
                                <div>
                                    <span>Max:</span>
                                    {userBalances[inputCollateralToken.symbol] ? <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                if (new BigNumber(userBalances[inputCollateralToken.symbol]).eq(0)) {
                                                    return
                                                }
                                                setInputCollateralToken(prevState => ({
                                                    ...prevState,
                                                    value: userBalances[inputCollateralToken.symbol].split(' ')[0]
                                                }))
                                            }}
                                        >
                                            {userBalances[inputCollateralToken.symbol]}
                                        </button>
                                        :
                                        <span>0 {inputCollateralToken.symbol}</span>
                                    }
                                    <span>
                                        <a
                                            onClick={() => {
                                                dispatch(setDepositModal(true))
                                            }}
                                            style={{cursor: "pointer"}}
                                        >deposit</a>
                                    </span>
                                </div>
                            </div>
                            <div className="c-add__form">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        // this.addValueSubmit();
                                    }}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="input-group">
                                                <InputAmount
                                                    className="input-control"
                                                    token={inputCollateralToken}
                                                    setToken={setInputCollateralToken}
                                                    disabled={!userBalances[inputCollateralToken.symbol]}
                                                />
                                                <SelectCoin
                                                    partnerTokens={partnerTokens}
                                                    token={inputCollateralToken}
                                                    setToken={setInputCollateralToken}
                                                />
                                            </div>
                                            {userBalances[inputCollateralToken.symbol] && parseFloat(userBalances[inputCollateralToken.symbol]) < parseFloat(inputCollateralToken.value) ?
                                                <div className="link-about">
                                                    <span className={'text-red'}>Insufficient balance. Make <a
                                                        onClick={() => {
                                                            dispatch(setDepositModal(true))
                                                        }}
                                                        style={{cursor: "pointer"}}
                                                    >deposit</a></span>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn"
                                                disabled={isDisabled()}
                                                onClick={addCollateral}
                                            >Add Collateral
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

AddCollateralModal.propTypes = {
    market: PropTypes.bool,
    profile: PropTypes.bool,
    ual: PropTypes.object.isRequired,
}
export default AddCollateralModal
