import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {withUAL} from 'ual-reactjs-renderer'
import Tippy from '@tippyjs/react'
import CopyBlock from '../CopyBlock'
import AddCollateralModal from '../modals/AddCollateralModal'
import {
    getAddCollateralModal,
    getApproveAccountModal,
    getCreateOfferModal,
    getDepositModal,
    getTransferModal
} from '../../store/appSlice'
import DepositModal from '../modals/DepositModal'
import {
    getToken,
    getTokenMetadata, getTokenOffer,
    requestToken,
    requestTokenCollateral,
    requestTokenMetadata, requestTokenOffer, resetToken,
} from '../../store/tokenSlice'
import TokenImage from './TokenImage'
import TokenDetailsData from './TokenDetailsData'
import TransferModal from '../modals/TransferModal'
import CreateOfferModal from '../modals/CreateOfferModal'
import ApproveAccountModal from '../modals/ApproveAccountModal'
import TokenActions from './TokenActions'

import icon_loading from '../../static/pics/loading.svg'
import PropTypes from "prop-types";

const TokenDetails = (props) => {
    const [loading, setLoading] = useState(false)
    const addCollateralModal = useSelector(getAddCollateralModal)
    const approveAccountModal = useSelector(getApproveAccountModal)
    const createOfferModal = useSelector(getCreateOfferModal)
    const depositModal = useSelector(getDepositModal)
    const transferModal = useSelector(getTransferModal)
    const token = useSelector(getToken)
    const metadata = useSelector(getTokenMetadata)
    const offer = useSelector(getTokenOffer)
    const {tokenId} = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetToken())
        }
    }, [])
    useEffect(() => {
        if (!token && !loading) {
            setLoading(true)
            dispatch(requestToken(tokenId))
        } else if (token === undefined && loading) {
            setLoading(false)
        } else if(tokenId !== token.wrapped_token_id && !loading) {
            setLoading(true)
            dispatch(requestToken(tokenId))
        } else {
            setLoading(false)
            dispatch(requestTokenCollateral(tokenId))
            dispatch(requestTokenMetadata(token.token_id))
            dispatch(requestTokenOffer(tokenId))
        }
    }, [tokenId, token])

    const isOwner = () => {
        return props.ual.activeUser && token.owner === props.ual.activeUser.accountName
    }

    return <>
        <main className="s-main s-main_centered">
            <div className="container">
                {loading ? <div className="lp-list__footer">
                        <img className="loading" src={icon_loading} alt=""/>
                    </div>
                    : (token ? <div className="w-card">
                                <div className="bg">
                                    <div className="w-card__nft">
                                        <div className="form-row">
                                            <div className="block">
                                                <div className="label">Asset ID
                                                    <Tippy
                                                        content={'Original asset ID'}
                                                        appendTo={document.getElementsByClassName("wrapper")[0]}
                                                        trigger='mouseenter'
                                                        interactive={false}
                                                        arrow={false}
                                                        maxWidth={260}
                                                    >
                                                        <span className="i-tip"></span>
                                                    </Tippy>
                                                </div>
                                                <CopyBlock
                                                    text={token.token_id}
                                                />
                                            </div>
                                            <div className="block">
                                                <div className="label">Token ID
                                                    <Tippy
                                                        content={'Wrapped token ID'}
                                                        appendTo={document.getElementsByClassName("wrapper")[0]}
                                                        trigger='mouseenter'
                                                        interactive={false}
                                                        arrow={false}
                                                        maxWidth={260}
                                                    >
                                                        <span className="i-tip"></span>
                                                    </Tippy>
                                                </div>
                                                <CopyBlock
                                                    text={token.wrapped_token_id}
                                                />
                                            </div>
                                            <div className="block">
                                                <TokenActions
                                                    isOwner={isOwner()}
                                                    offer={offer}
                                                    token={token}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <TokenImage
                                        tokenId={token.wrapped_token_id}
                                        metadata={metadata}
                                    />
                                    <TokenDetailsData
                                        isOwner={isOwner()}
                                        token={token}
                                        ual={props.ual}
                                    />
                                </div>
                            </div>
                            :
                            <div className="lp-list__footer">
                                Token not found
                            </div>
                    )
                }
            </div>
        </main>
        {addCollateralModal && <AddCollateralModal
            ual={props.ual}
        />}
        {depositModal && <DepositModal
            ual={props.ual}
        />}
        {createOfferModal && <CreateOfferModal
            profile={true}
            ual={props.ual}
        />}
        {approveAccountModal && <ApproveAccountModal
            ual={props.ual}
        />}
        {transferModal && <TransferModal
            ual={props.ual}
        />}
    </>
}

TokenDetails.propTypes = {
    ual: PropTypes.object,
}
export default withUAL(TokenDetails)
