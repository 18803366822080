import {ExplorerApi} from 'atomicassets'

//const api = new ExplorerApi('https://test.wax.api.atomicassets.io', 'atomicassets', {fetch})
const api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {fetch})

export const AtomicAssetsApi = {
    getAssetMetadata: async (id) => {
        return (await api.getAsset(id)).data
    },
    getAssets: async (owner) => {
        return await api.getAssets({
            owner: owner
        })
    },
}
