import React from 'react'
import {Link} from 'react-router-dom'
import {withUAL} from 'ual-reactjs-renderer'
import ConnectButton from './wallet/ConnectButton'

import icon_onb_1     from '../static/pics/onb-1.png'
import icon_onb_1_mob from '../static/pics/onb-1-mob.png'
import icon_onb_2     from '../static/pics/onb-2.png'
import icon_onb_2_mob from '../static/pics/onb-2-mob.png'
import icon_bg_onb    from '../static/pics/bg/bg-onb.svg'
import PropTypes from "prop-types";

const MainPage = (props) => {
    return <main className="s-main">
        <section className="onb-intro">
            <img className="onb-bg" src={icon_bg_onb} alt=""/>
            <div className="container">
                <picture className="onb-intro__img">
                    <source srcSet={icon_onb_1} media="(min-width: 1024px)"/>
                    <img src={icon_onb_1_mob} alt=""/>
                </picture>
                <div className="onb-intro__text">
                    <h1 className="h1">Add <span
                        className="text-grad">additional</span> parameters for your nft, such as royalties and collateral.
                    </h1>
                    <p>Keep valuables, earn on resale or just unwrap collateral</p>
                    {props.ual.activeUser ? <Link
                        className="btn"
                        to={`/profile/${props.ual.activeUser.accountName}`}
                    >Wrap NFT</Link> : <ConnectButton ual={props.ual}/>}
                </div>
            </div>
        </section>
        <div className="divider left"></div>
        <section className="onb-wrap">
            <div className="container">
                <div className="onb-wrap__text">
                    <h2 className="h2">NIFTSY Collateral Smart Contract Journey</h2>
                    <p>You can create an NFT on ENVELOP, wrap it with collateral and sell. The transfer will be registered by the oracle, and the protocol will put a share of the money paid on the transfer into the wrapped collateral, elevating the NFT’s value.</p>
                </div>
                <div className="onb-wrap__img">
                    <picture>
                        <source srcSet={icon_onb_2} media="(min-width: 1024px)"/>
                        <img src={icon_onb_2_mob} alt=""/>
                    </picture>
                </div>
            </div>
        </section>
    </main>
}

MainPage.propTypes = {
    ual: PropTypes.object,
}
export default withUAL(MainPage)
