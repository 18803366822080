import React from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {setApproveAccountModal} from '../../store/appSlice'
import {sendingTransaction, sleep} from '../../utils/functions'
import {MARKETPLACE_CONTRACT, PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {requestApprovedAccounts} from '../../store/accountSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const ApproveAccountModal = (props) => {
    const dispatch = useDispatch()

    const approve = () => {
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'approveall',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        owner: props.ual.activeUser.accountName,
                        to: MARKETPLACE_CONTRACT,
                        approved: true,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(setApproveAccountModal(false))
            dispatch(requestApprovedAccounts(props.ual.activeUser.accountName))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const closePopup = () => {
        dispatch(setApproveAccountModal(false))
    }

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation()
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Approve marketplace account</div>
                        </div>
                        <div className="c-add__coins">
                            <div className="c-add__form">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <button
                                                className="btn"
                                                onClick={approve}
                                            >Approve
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

ApproveAccountModal.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default ApproveAccountModal
