import React from 'react'

import icon_logo from '../static/pics/logo.svg'

const Footer = () => {
    return <footer className="s-footer">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-auto">
                    <div className="s-footer__logo">
                        <img src={icon_logo} alt=""/>
                    </div>
                </div>
                <div className="col-12 col-md-auto">
                    <ul className="s-footer__menu">
                        <li><a target="_blank" rel="noopener noreferrer"
                               href="https://saft.envelop.is/">About SAFT wNFT</a></li>
                        <li><a target="_blank" rel="noopener noreferrer"
                               href="https://envelop.is/">Protocol</a></li>
                        <li><a target="_blank" rel="noopener noreferrer"
                               href="https://envelop.medium.com/nfts-market-meta-analysis-by-niftsy-e9f131234041">Analytics</a>
                        </li>
                        <li><a target="_blank" rel="noopener noreferrer"
                               href="https://scotch.sale">SAFT wNFT Market</a></li>
                        <li><a target="_blank" rel="noopener noreferrer"
                               href="https://t.me/envelop_en">Support</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
