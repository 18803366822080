import React from 'react'
import PropTypes from 'prop-types'

const ConnectButton = (props) => {
    return <button
        className="btn btn-sm"
        onClick={props.ual.showModal}
    >
        Connect wallet
    </button>
}

ConnectButton.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default ConnectButton
