import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AtomicAssetsApi} from '../utils/atomicAssetsApi'
import {WaxApi} from '../utils/waxApi'

export const requestTokenCollateral = createAsyncThunk(
    'token/requestTokenCollateral',
    async (id) => {
        return (await WaxApi.loadTokenCollateral(id)).rows
    }
)
export const requestTokenMetadata = createAsyncThunk(
    'token/requestTokenMetadata',
    async (id) => {
        return await AtomicAssetsApi.getAssetMetadata(id)
    }
)
export const requestTokenOffer = createAsyncThunk(
    'token/requestTokenOffer',
    async (id) => {
        return (await WaxApi.loadTokenOffer(id)).rows[0]
    }
)
export const requestToken = createAsyncThunk(
    'token/requestToken',
    async (id) => {
        return await WaxApi.loadToken(id)
    }
)

const initialState = {
    collateral: null,
    offer: null,
    token: null,
    metadata: null,
}

export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        resetToken: (state) => {
            state.collateral = null
            state.offer = null
            state.metadata = null
            state.token = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestTokenCollateral.fulfilled, (state, action) => {
            state.collateral = action.payload
        })
        builder.addCase(requestTokenMetadata.fulfilled, (state, action) => {
            state.metadata = action.payload
        })
        builder.addCase(requestTokenOffer.fulfilled, (state, action) => {
            state.offer = action.payload
        })
        builder.addCase(requestToken.fulfilled, (state, action) => {
            state.token = action.payload
        })
    },
})

export const getTokenCollateral = (state) => {
    const collateral = state.token.collateral
    if (!collateral) {
        return []
    }
    let result = []
    for (let i = 0; i < collateral.length; i++) {
        result[i] = {
            ...collateral,
            amount: collateral[i].quantity.split(' ')[0],
            symbol: collateral[i].quantity.split(' ')[1],
        }
    }
    return result
}
export const getTokenMetadata = (state) => state.token.metadata
export const getTokenOffer = (state) => state.token.offer
export const getToken = (state) => state.token.token

export const {resetToken} = tokenSlice.actions

export default tokenSlice.reducer
