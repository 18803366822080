import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {useDispatch, useSelector} from 'react-redux'
import {
    getOfferByTokenId,
    getTokenMetadata,
    requestMetadata,
    requestOfferByTokenId,
    requestTokenCollateral
} from '../../store/profileSlice'
import CopyBlock from '../CopyBlock'
import TokenCollateralBlock from './TokenCollateralBlock'
import TokenAccumulatedFeeBlock from './TokenAccumulatedFeeBlock'
import TokenTransferFeeBlock from './TokenTransferFeeBlock'
import TokenRoyaltyBlock from './TokenRoyaltyBlock'
import TokenCardFooter from './TokenCardFooter'
import TokenImage from './TokenImage'
import TokenActions from './TokenActions'
import TokenUnwrapAfterBlock from './TokenUnwrapAfterBlock'
import TokenUnwraptFeeThresholdBlock from './TokenUnwraptFeeThresholdBlock'

const TokenShort = (props) => {
    const metadata = useSelector(getTokenMetadata(props.token.token_id))
    const offer = useSelector(getOfferByTokenId(props.token.wrapped_token_id))

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestTokenCollateral(props.token.wrapped_token_id))
        if (!metadata) {
            dispatch(requestMetadata(props.token.token_id))
        }
        dispatch(requestOfferByTokenId(props.token.wrapped_token_id))
    }, [])

    const isOwner = () => {
        return !!props.ual.activeUser && props.token.owner === props.ual.activeUser.accountName
    }

    return <div className="c-col">
        <div className="w-card w-card-preview">
            <div className="bg">
                <div className="w-card__nft">
                    <div>
                        <CopyBlock
                            text={props.token.wrapped_token_id}
                            title={'ID'}
                            isTippy={true}
                            width={512}
                        />
                    </div>
                    <TokenActions
                        isOwner={isOwner()}
                        token={props.token}
                        offer={offer}
                    />
                </div>
                <TokenImage
                    link={true}
                    metadata={metadata}
                    offer={offer}
                    tokenId={props.token.wrapped_token_id}
                />
                <div className="w-card__param">
                    <div className="form-row">
                        <div className="field-wrap">
                            <div className="field-row">
                                <label className="field-label">Token name</label>
                            </div>
                            <div className="field-row">
                                <div className="field-control">
                                    <span>{metadata && metadata.name !== '' ? metadata.name : '-'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(props.type === 'wrapped' || props.type === 'offers') && <>
                        <div className="form-row">
                            <TokenCollateralBlock
                                isShortData={true}
                                tokenId={props.token.wrapped_token_id}
                            />
                            {props.type === 'offers' && <TokenUnwraptFeeThresholdBlock
                                unwraptFeeThreshold={props.token.unwrapt_fee_threshold}
                                accumulatedFee={props.token.accumulated_fee}
                            />}
                            <TokenAccumulatedFeeBlock accumulatedFee={props.token.accumulated_fee}/>
                            <TokenTransferFeeBlock transferFee={props.token.transfer_fee}/>
                            <TokenRoyaltyBlock royaltyPercent={props.token.royalty_percent}/>
                            {props.type === 'offers' && <TokenUnwrapAfterBlock unwrapAfter={props.token.unwrap_after}/>}
                        </div>
                    </>
                    }
                    <TokenCardFooter
                        isOwner={isOwner()}
                        token={props.token}
                        type={props.type}
                        ual={props.ual}/>
                </div>
            </div>
        </div>
    </div>
}

TokenShort.propTypes = {
    token: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    ual: PropTypes.object,
}
export default withUAL(TokenShort)
