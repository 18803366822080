import React from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import CopyBlock from '../CopyBlock'
import Tippy from '@tippyjs/react'

import icon_i_link from '../../static/pics/i-link.svg'
import icon_logo from '../../static/pics/logo.svg'
import icon_onsale from '../../static/pics/onsale.png'

const Image = (props) => {
    return (!props.metadata || !props.metadata.img || props.metadata.img === '') ?
        <div className="inner">
            <div className="default">
                <img src={icon_logo} alt=""/>
                <span>Cannot load NON-FUNGIBLE TOKEN Preview</span>
            </div>
        </div>
        :
        <div className="inner">
            <video
                className="img"
                src={`https://ipfs.io/ipfs/${props.metadata.img}`}
                poster={`https://ipfs.io/ipfs/${props.metadata.img}`}
                autoPlay={true}
                muted={true}
                loop={true}
            />
        </div>
}
Image.propTypes = {
    metadata: PropTypes.object,
}

const TokenImage = (props) => {
    return <div className="w-card__token">
        <CopyBlock
            text={`${window.location.origin}/tokens/${props.tokenId}`}
            hideText={true}
            icon={icon_i_link}
            className={'btn-token-link'}
        />
        {props.offer ?
            <Tippy
                content={props.offer.price}
                appendTo={document.getElementsByClassName("wrapper")[0]}
                trigger='mouseenter'
                interactive={false}
                arrow={false}
                maxWidth={props.width || 512}
            >
                <button className={'btn-token-onsale'} style={{float: 'left'}}>
                    <img src={icon_onsale} width={30} alt=""/>
                </button>
            </Tippy>
            :
            null
        }
        {props.link ?
            <Link to={`/tokens/${props.tokenId}`}>
                <Image metadata={props.metadata}/>
            </Link>
            :
            <Image metadata={props.metadata}/>
        }
    </div>
}

TokenImage.propTypes = {
    link: PropTypes.bool,
    metadata: PropTypes.object,
    offer: PropTypes.object,
    tokenId: PropTypes.number.isRequired,
    width: PropTypes.number,
}
export default TokenImage
