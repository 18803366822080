import React from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {addThousandSeparator, removeThousandSeparator} from '../../utils/functions'

const InputAmount = (props) => {
    return <input
        className={props.className || 'field-control'}
        placeholder="0.00"
        type="text"
        value={addThousandSeparator(props.token.value)}
        onChange={(e) => {
            let value = removeThousandSeparator(e.target.value);
            if (value.split('.')[1] && value.split('.')[1].length > props.token.decimals) {
                return
            }
            if (value.startsWith('00')) {
                return
            }
            if (value !== '' && !value.endsWith('.') && !value.endsWith('0')) {
                if (new BigNumber(value).isNaN()) {
                    return
                }
                value = new BigNumber(value).toString()
            }
            props.setToken((prevState) => ({...prevState, value: value}))
        }}
        disabled={props.disabled}
    />

}

InputAmount.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    token: PropTypes.shape({
        value: PropTypes.string.isRequired,
        decimals: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
    }).isRequired,
    setToken: PropTypes.func.isRequired,
}
export default InputAmount
