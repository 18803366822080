import React from 'react'
import PropTypes from 'prop-types'

const TokenRoyaltyBlock = (props) => {
    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">Royalty</label>
        </div>
        <div className="field-row">
            <div className="field-control">
                <span>{props.royaltyPercent > 0 ? props.royaltyPercent : '—'}</span>
            </div>
            {props.royaltyPercent > 0 && <span className="field-unit">%</span>}
        </div>
    </div>
}

TokenRoyaltyBlock.propTypes = {
    royaltyPercent: PropTypes.number.isRequired,
}
export default TokenRoyaltyBlock
