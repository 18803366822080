import React from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {addThousandSeparator} from '../../utils/functions'

import default_icon from '../../static/pics/coins/_default.png'

const TokenAccumulatedFeeBlock = (props) => {
    const feeAmount = new BigNumber(props.accumulatedFee.split(' ')[0])
    const feeSymbol = feeAmount.gt(0) ? props.accumulatedFee.split(' ')[1] : null


    return <div className="field-wrap">
        <div className="field-row">
            <label className="field-label">Accumulated Fee</label>
        </div>
        <div className="field-row">
            <div className="field-control">
                <span>{feeAmount.gt(0) ? addThousandSeparator(feeAmount.toString()) : '—'}</span>
            </div>
            {feeSymbol ?
                <span className="field-unit">
                    <span className="i-coin"><img src={default_icon} alt=""/></span>
                    {feeSymbol}
                </span>
                :
                null
            }
        </div>
    </div>
}

TokenAccumulatedFeeBlock.propTypes = {
    accumulatedFee: PropTypes.string.isRequired,
}
export default TokenAccumulatedFeeBlock
