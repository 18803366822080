import React from 'react'
import PropTypes from 'prop-types'
import AtomicAssetsShort from '../atomicAssets/AtomicAssetsShort'
import TokenShort from './TokenShort'

import icon_loading from '../../static/pics/loading.svg'

const TokensList = (props) => {
    return <div className="db-section">
        <div className="divider right short-20"></div>
        <div className="container">
            <div className="c-header">
                <div className="h3">{props.title}</div>
            </div>
            {!props.tokens ? <div className="lp-list__footer">
                    <img className="loading" src={icon_loading} alt=""/>
                </div>
                :
                props.tokens.length > 0 ?
                    <>
                        <div className="c-row">
                            {props.type === 'atomicAssets' ?
                                props.tokens.map(
                                    (item) => <AtomicAssetsShort key={item.asset_id} asset={item}/>
                                )
                                :
                                props.tokens.map(
                                    (item) => <TokenShort key={item.wrapped_token_id} type={props.type}
                                                          token={item}/>
                                )
                            }
                        </div>
                        {props.more && <div className="pagination">
                            <ul>
                                <li key={0}>
                                    <button
                                        style={{width: '300px'}}
                                        className={'btn'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            props.loadMore()
                                        }}
                                    >
                                        Load more...
                                    </button>
                                </li>
                            </ul>
                        </div>
                        }
                    </>
                    :
                    <div className="lp-list__footer">
                        <div className="nomore">There&apos;s no more wNFT yet</div>
                    </div>
            }
        </div>
    </div>
}

TokensList.propTypes = {
    loadMore: PropTypes.func,
    more: PropTypes.bool,
    title: PropTypes.string.isRequired,
    tokens: PropTypes.array,
    type: PropTypes.oneOf(['atomicAssets', 'offers', 'waiting', 'wrapped']).isRequired,
}
export default TokensList
