import React, {useEffect, useState} from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {getTransferToken, setDepositModal, setTransferModal} from '../../store/appSlice'
import InputAddress from '../forms/InputAddress'
import {PROTOCOL_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {isAccount, sendingTransaction, sleep} from '../../utils/functions'
import {requestTokenCollateral, resetToken} from '../../store/tokenSlice'
import {getUserBalances, requestUserBalances} from '../../store/accountSlice'
import {requestBalances, requestWNFTs} from '../../store/profileSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const TransferModal = (props) => {
    const [inputAddress, setInputAddress] = useState('')
    const userBalances = useSelector(getUserBalances)
    const token = useSelector(getTransferToken)
    const transferFee = new BigNumber(token.transfer_fee.split(' ')[0])
    const transferToken = !transferFee.isNaN() && transferFee.gt(0) ? {
        amount: transferFee,
        symbol: token.transfer_fee.split(' ')[1]
    } : null
    const availableBalance = transferToken ? (userBalances[transferToken.symbol] ? new BigNumber(userBalances[transferToken.symbol].split(' ')[0]) : 0) : 0

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestUserBalances(props.ual.activeUser.accountName))
    }, [])

    const closePopup = () => {
        dispatch(setTransferModal(false))
    }
    const isDisabled = () => {
        if (inputAddress === '') {
            return true
        }
        if (!isAccount(inputAddress)) {
            return true
        }
        if (transferToken && transferToken.amount.gt(availableBalance)) {
            return true
        }
        return false
    }
    const transfer = () => {
        const tr = {
            actions: [
                {
                    account: PROTOCOL_CONTRACT,
                    name: 'transfer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        wrapped_token_id: token.wrapped_token_id,
                        to: inputAddress,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(setTransferModal(false))
            dispatch(resetToken())
            if (transferToken) {
                dispatch(requestUserBalances(props.ual.activeUser.accountName))
            }
            if (props.profile) {
                dispatch(requestWNFTs(props.ual.activeUser.accountName))
                dispatch(requestBalances(props.ual.activeUser.accountName))
            } else {
                dispatch(requestTokenCollateral(token.wrapped_token_id))
            }
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation();
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Transfer token</div>
                            <p>After this action you will not own this wrapped NFT</p>
                            {transferToken ?
                                <>
                                    <p>Transfer fee: {token.transfer_fee}</p>
                                    <span>You have: </span>
                                    {userBalances[transferToken.symbol]}
                                    {transferToken.amount.gt(availableBalance) ?
                                        <p>Insufficient funds. Please <a
                                            onClick={() => {
                                                dispatch(setDepositModal(true))
                                            }}
                                            style={{cursor: "pointer"}}
                                        >deposit</a> {transferToken.symbol}</p>
                                        :
                                        null
                                    }
                                </>
                                :
                                <p>No transfer fee</p>
                            }
                        </div>
                        <div className="c-add__max">
                        </div>
                        <div className="c-add__coins">
                            <div className="c-add__form">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <InputAddress
                                                address={inputAddress}
                                                setAddress={setInputAddress}
                                            />
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn"
                                                type="button"
                                                disabled={isDisabled()}
                                                onClick={transfer}
                                            >Transfer
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

TransferModal.propTypes = {
    profile: PropTypes.bool,
    ual: PropTypes.object.isRequired,
}
export default TransferModal
