import React, {useState} from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {
    getCreateOfferToken, setApproveAccountModal,
    setCreateOfferModal,
    setCreateOfferToken
} from '../../store/appSlice'
import InputAmount from '../forms/InputAmount'
import SelectCoin from '../forms/SelectCoin'
import {balanceToFixed, sendingTransaction, sleep} from '../../utils/functions'
import {MARKETPLACE_CONTRACT, SLEEP_AFTER_TRANSACTION} from '../../utils/constant'
import {getApprovedAccounts} from '../../store/accountSlice'
import {requestTokenCollateral, resetToken} from '../../store/tokenSlice'
import {requestWNFTs} from '../../store/profileSlice'

BigNumber.config({DECIMAL_PLACES: 50, EXPONENTIAL_AT: 100})

const CreateOfferModal = (props) => {
    const [inputPriceToken, setInputPriceToken] = useState({value: '', decimals: 0, symbol: ''})
    const partnerTokens = [{
        decimals: 8,
        max_supply: '46116860184.27387903 WAX',
        partner_token_contract: 'eosio.token',
        symbol: 'WAX',
        value: '',
    }]
    const tokenId = useSelector(getCreateOfferToken)
    const approvedAccounts = useSelector(getApprovedAccounts)

    const dispatch = useDispatch()

    const createOffer = () => {
        const amount = new BigNumber(inputPriceToken.value).isNaN() ? new BigNumber(0) : new BigNumber(inputPriceToken.value)
        if (!amount.gt(0)) {
            return
        }
        const price = balanceToFixed(`${amount} ${inputPriceToken.symbol}`, inputPriceToken.decimals)
        const tr = {
            actions: [
                {
                    account: MARKETPLACE_CONTRACT,
                    name: 'createoffer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        seller: props.ual.activeUser.accountName,
                        wrapped_token_id: tokenId,
                        price: price,
                    }
                }
            ]
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            if (props.profile) {
                dispatch(requestWNFTs(props.ual.activeUser.accountName))
            } else {
                dispatch(requestTokenCollateral(tokenId))
            }
            dispatch(resetToken())
            dispatch(setCreateOfferModal(false))
            dispatch(setCreateOfferToken(null))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const closePopup = () => {
        dispatch(setCreateOfferModal(false))
    }
    const isApproved = () => {
        for (let i = 0; i < approvedAccounts.length; i++) {
            if (approvedAccounts[i].approval === MARKETPLACE_CONTRACT) {
                return true
            }
        }
        return false
    }
    const isDisabled = () => {
        if (!isApproved()) {
            return true
        }
        if (!inputPriceToken.value) {
            return true
        }
        const value = new BigNumber(inputPriceToken.value)
        if (!value.gt(0)) {
            return true
        }
        return false
    }
    const openApproveModal = () => {
        dispatch(setApproveAccountModal(true))
    }

    return <div className="modal">
        <div
            className="modal__inner"
            onClick={(e) => {
                e.stopPropagation()
                if (e.target.className === 'modal__inner') {
                    closePopup()
                }
            }}
        >
            <div className="modal__bg"></div>
            <div className="container">
                <div className="modal__content">
                    <div
                        className="modal__close"
                        onClick={() => {
                            closePopup()
                        }}
                    >
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M35.9062 36.3802L0.69954 1.17351L1.25342 0.619629L36.4601 35.8263L35.9062 36.3802Z"
                                  fill="white"></path>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.699257 36.3802L35.9059 1.17351L35.3521 0.619629L0.145379 35.8263L0.699257 36.3802Z"
                                  fill="white"></path>
                        </svg>
                    </div>
                    <div className="c-add">
                        <div className="c-add__text">
                            <div className="h2">Create offer</div>
                            {!isApproved() && <p>You must <a
                                style={{cursor: "pointer"}}
                                onClick={openApproveModal}
                            >approve</a> {MARKETPLACE_CONTRACT} account.</p>}
                        </div>
                        <div className="c-add__coins">
                            <label className="input-label">
                                Price
                            </label>
                            <div className="c-add__form">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="input-group">
                                                <InputAmount
                                                    className="input-control"
                                                    token={inputPriceToken}
                                                    setToken={setInputPriceToken}
                                                />
                                                <SelectCoin
                                                    partnerTokens={partnerTokens}
                                                    token={inputPriceToken}
                                                    setToken={setInputPriceToken}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn"
                                                disabled={isDisabled()}
                                                onClick={createOffer}
                                            >Create offer
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

CreateOfferModal.propTypes = {
    profile: PropTypes.bool,
    ual: PropTypes.object.isRequired,
}
export default CreateOfferModal
