import {setModalError, setModalText, setModalType} from '../store/appSlice'
import {createInitialTypes, SerialBuffer} from 'eosjs/dist/eosjs-serialize'
import {monthsNames} from './constant'

export const addThousandSeparator = (numStr) => {
    const parts = numStr.split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        + (parts[1] ? '.' + parts[1] : '')
        + (numStr.endsWith('.') ? '.' : '')
}
export const balanceToFixed = (balance, precision) => {
    const splitted = balance.split(' ')
    if (splitted.length !== 2) {
        return balance
    }
    return `${parseFloat(splitted[0]).toFixed(precision)} ${splitted[1]}`
}
export const isAccount = (name) => {
    if (name.length > 12) {
        return false
    }
    const regex = /^[a-z1-5.]+$/
    return regex.exec(name) !== null
}
export const nameToInt = (name) => {
    const builtinTypes = createInitialTypes()
    const typeUint64 = builtinTypes.get("uint64")
    const typeName = builtinTypes.get("name")
    let buffer = new SerialBuffer({textDecoder: new TextDecoder(), textEncoder: new TextEncoder()});

    typeName.serialize(buffer, name)
    return typeUint64.deserialize(buffer)
}
export const removeThousandSeparator = (numStr) => {
    return numStr.replaceAll(',', '.').replaceAll(' ', '')
}
export const sendingTransaction = async (tr, dispatch, activeUser, callback) => {
    dispatch(setModalText('Signing transaction...'))
    dispatch(setModalType('loading'))
    try {
        let result = await activeUser.signTransaction(tr, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
        })
        if (result.status && result.status === "executed") {
            await callback()
            dispatch(setModalType(null))
        } else {
            dispatch(setModalError({name: 'Transaction error', message: result}))
            dispatch(setModalType('error'))
        }
    } catch (error) {
        dispatch(setModalError({name: error.name, message: error.message}))
        dispatch(setModalType('error'))
    }
    dispatch(setModalText(null))
}
export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export const unixtimeToStr = (unixtime) => {
    const date = new Date(unixtime)
    return `${date.getDate()} ${monthsNames[date.getMonth()]} ${date.getFullYear()}`
}
